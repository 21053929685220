import React, { useState, useEffect, useRef, createRef, RefObject } from 'react';
import { useParams, useRouteMatch, useHistory, useLocation } from 'react-router';
import { State, TramitesDisponibles, Institucion, Campos, Banco, RealEstate, States } from 'sigt';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Typography,
  Col,
  Row,
  Select,
  Divider,
  Form,
  Button,
  List,
  Input,
  DatePicker,
  Table,
  Progress,
  Modal,
  message,
} from 'antd';
import { getValidators, ExtendedDecoratorOptions } from '../../utils/formGenValidators';
import { updateProcedure } from '../../redux/actions/procedures';
import { SelectProps } from 'antd/lib/select';
import '../../assets/css/components/FormGenerator.css';
import { useWindowDimensions } from '../../utils/hooks';
import { flatten } from 'lodash';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { setProcedure } from '../../redux/actions/procedures';
import { DeleteOutlined, CloudDownloadOutlined } from '@ant-design/icons';
const server = process.env.REACT_APP_SERVER_URL;

const FormGenerator: React.FC<FormGeneratorProps> = ({
  institutions,
  banks,
  token,
  setProcedure,
  thm,
  auth,
  prcd,
  updateProcedure,
}) => {
  const [selectedProcedure, setSelectedProcedure] = useState<TramitesDisponibles | undefined>(undefined);
  const [selectedInstitution, setSelectedInstitution] = useState<Institucion | undefined>(undefined);
  const [values, setValues] = useState({});
  const [solvenciaRRICheckbox, setSolvenciaRRICheckbox] = useState<boolean>(true);
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [created, setCreated] = useState<boolean>(false);
  const [realEstate, setRealEstate] = useState<RealEstate | null>(null);
  const [actualProcedure, setActualProcedure] = useState<any>();
  const [observations, setObservations] = useState<string | null>(null);

  const recaudosRef = useRef<RefObject<HTMLImageElement>[]>([]);
  const refs = useRef<any>([]);

  const [form] = Form.useForm();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { idInst, idTramite, idProceso } = useParams();

  useRouteMatch('/dashboard/tramite/:idInst/:idTramite/:idProceso?');

  useEffect(() => {
    const fetchObservations = async (idTramite: number, authToken) => {
      const { data } = await axios.get(`${server}/observation?idTramite=${idTramite}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (data?.observaciones) setObservations(data?.observaciones);
    };
    fetchObservations(idProceso, token);
  }, [idProceso, token]);

  useEffect(() => {
    const actualPrcd = prcd.procedures.find((p) => p.id === parseInt(idProceso || '0'));
    setActualProcedure(actualPrcd);
  }, [idProceso, prcd.procedures]);

  useEffect(() => {
    if (idInst && idTramite && institutions) {
      const institution = institutions.find((i) => i.id === parseInt(idInst));
      const selected = institution?.tramitesDisponibles?.find(
        (t) => t.id === (parseInt(idTramite) === 43 ? 16 : parseInt(idTramite))
      );
      // if(idProceso){
      //   selected?.secciones?.filter(s => s.nombre !== 'Datos de Pago')
      // }
      setSelectedInstitution(institution);
      setSelectedProcedure(selected);
      refs.current = selected ? selected?.secciones?.map((s) => s.campos.map(() => createRef())) : [];
      recaudosRef.current = selected ? selected.recaudos.filter((r) => !r.fisico).map(() => createRef<HTMLImageElement>()) : [];
    } else {
      history.push('/dashboard');
    }
    return () => {
      refs.current = [];
      recaudosRef.current = [];
    };
  }, [idInst, idTramite, history, institutions]);

  useEffect(() => {
    if (banks) {
      form.setFieldsValue({
        banco: banks[0]?.id,
      });
    }
    // eslint-disable-next-line
  }, [banks]);

  useEffect(() => {
    form.resetFields();
    if (recaudosRef.current.find((c) => c.current)) recaudosRef.current = [];
    setFiles(null);
    setRealEstate(null);
    // eslint-disable-next-line
  }, [idInst, idTramite]);

  useEffect(() => {
    if (idTramite !== '27') {
      form.setFieldsValue({
        direccion: auth.user?.direccion,
        nombre: auth.user?.nombreCompleto,
        correo: auth.user?.nombreUsuario,
        cedula: auth.user?.cedula,
        telefono: auth.user?.telefono,
      });
    }
    // eslint-disable-next-line
  }, [auth, idTramite]);

  useEffect(() => {
    if (idProceso) {
      const proceso = prcd.procedures.find((i) => i.id === parseInt(idProceso));
      const datos = proceso?.datos;
      form.setFieldsValue({
        ...datos,
      });
    }
    // eslint-disable-next-line
  }, [prcd]);

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const searchEstate = async (value: string) => {
    const response = await axios.get(`${server}/estates/${value}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      response.data.data
        ? setRealEstate(response.data.data)
        : message.error(
            'No se ha encontrado el código catastral en el registro de inmuebles urbanos. Verifique el código ingresado o realice el tramite para código catastral del Centro de de Procesamiento Urbano'
          );
    }
  };

  const getFormItem = (fields: Campos[], sectIndex: number): JSX.Element[] => {
    const filteredFields = fields.filter((field, index) => fields.findIndex((f) => f.id === field.id) === index);
    return filteredFields.map((f, i) => {
      const struct = getValidators(
        f.validacion,
        auth.user?.tipoUsuario || 4,
        undefined,
        setSolvenciaRRICheckbox,
        actualProcedure?.tipoTramite,
        0
      );
      const { extension } = struct;
      return (
        <Col xs={24} xl={f.col} key={f.id}>
          <Form.Item
            label={f.nombre || ''}
            name={f.validacion}
            rules={struct.validations.rules}
            normalize={struct.validations.normalize}
          >
            {getChildComponent(extension, sectIndex, i)}
          </Form.Item>
        </Col>
      );
    });
  };

  const getChildComponent = (extension: ExtendedDecoratorOptions, sectIndex: number, fieldIndex: number): JSX.Element => {
    const { Component } = extension;
    const Icon = extension.icon;
    return (
      <Component
        {...extension.props}
        prefix={Icon ? <Icon /> : null}
        addonBefore={
          extension.addonBefore
            ? getAddonBefore(
                extension.addonBefore.Component,
                extension.addonBefore.options || [],
                sectIndex,
                fieldIndex,
                extension.addonBefore.name,
                extension.addonBefore?.props
              )
            : null
        }
      />
    );
  };

  const getAddonBefore = (
    Component: typeof Select,
    options: string[],
    sectIndex: number,
    fieldIndex: number,
    name: string,
    props?: SelectProps<any>
  ): JSX.Element => {
    if (!values[name]) setValues({ ...values, [name]: options[0] });
    const setValue = (v) => {
      setValues({ ...values, [name]: v });
    };
    return (
      <Component
        {...props}
        defaultValue={options[0]}
        ref={(ref) => {
          if (ref) refs.current[sectIndex][fieldIndex] = { name, ref };
        }}
        value={values[name]}
        onChange={(c) => setValue(c)}
      >
        {options.map((opt, i) => (
          <Component.Option value={opt} key={`opt-${i}`}>
            {opt}
          </Component.Option>
        ))}
      </Component>
    );
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    values['solvenciaRRI'] = solvenciaRRICheckbox;
    flatten<any>(refs.current)
      .filter((r) => r.current !== null)
      .forEach((i) => {
        if (i.ref) values[i.name] = i.ref.props.value;
      });
    // const hasAllMandatoryTakings = files
    //   ? !selectedProcedure?.recaudos.filter((r) => r.obligatorio && !r.fisico).find((r) => !files.hasOwnProperty(r.nombreCorto))
    //   : false;
    const hasTakings = true;
      // (selectedProcedure?.recaudos &&
      //   selectedProcedure.recaudos.filter((r) => !r.fisico).length > 0 &&
      //   files &&
      //   hasAllMandatoryTakings) ||
      // (selectedProcedure?.recaudos && selectedProcedure.recaudos.filter((r) => !r.fisico).length === 0);
    const hasCatCode = !selectedProcedure?.necesitaCodCat || (selectedProcedure.necesitaCodCat && realEstate);
    if (!hasCatCode) {
      message.error('Debe ingresar el código catastral del inmueble y buscar sus datos para poder calcular el monto de pago.');
      return;
    }
    if (hasTakings) {
      Object.keys(values).forEach((k) => {
        if (moment.isMoment(values[k])) {
          values[k] = values[k].toISOString();
        }
      });
      const pago = selectedProcedure?.pagoPrevio
        ? {
            fecha: values.fecha,
            banco: values.banco,
            referencia: values.referencia,
            costo: selectedProcedure.necesitaCodCat
              ? (solvenciaRRICheckbox && parseInt(idTramite) === 16 ? selectedProcedure.costo * 2 : selectedProcedure.costo) *
                (realEstate?.metrosConstruccion || 1)
              : solvenciaRRICheckbox && parseInt(idTramite) === 16
              ? selectedProcedure.costo * 2
              : selectedProcedure.costo,
          }
        : undefined;
      delete values.fecha;
      delete values.banco;
      delete values.referencia;
      values.codCat = realEstate;
      const datos = { ...values, contribuyente: auth.user?.contribuyente };
      const fd = new FormData();
      if (files && Object.keys(files).length > 0) Object.keys(files).forEach((k) => fd.append('recaudos', files[k]));
      setLoading(true);
      let tramite = {
        datos,
        pago,
        tipoTramite: parseInt(idTramite) === 16 ? (solvenciaRRICheckbox ? 43 : 16) : parseInt(idTramite),
        estado: '',
        idTramite: 0,
        revision: parseInt(idTramite) !== 43 ? { aprobado: false } : { aprobado: 'cat' },
      };
      if (idProceso) {
        const proceso = prcd.procedures.find((i) => i.id === parseInt(idProceso));
        if (proceso?.estado === 'encorreccion') {
          tramite = {
            ...tramite,
            estado: proceso?.estado,
            idTramite: proceso?.id,
            revision: parseInt(idTramite) !== 43 ? { aprobado: false } : { aprobado: 'cat' },
          };
          try {
            await updateProcedure({ tramite, idUsuario: auth.user?.id }, auth.token || '');
            if (files && Object.keys(files).length > 0){
              await axios.post(`${server}/uploads/takings/${tramite.idTramite}/${true}`, fd ,{
                headers: { Authorization: `Bearer ${token}` },
                onUploadProgress: (progressEvent) => setProgress(Math.floor(progressEvent.loaded * 100))
              }
            );
            }
            message.success('Tramite procesado de manera exitosa');
            history.push('/dashboard/bandejas');
            setCreated(true);
            setLoading(false);
          } catch (e) {
            message.error('Error al procesar la solicitud');
            setLoading(false);
          }
        } else {
          console.log('Existe una id de proceso pero no esta encorreccion');
        }
      } else {
        try {
          delete tramite.estado;
          delete tramite.idTramite;
          delete tramite.revision;
          const resp = await axios.post(
            `${server}/procedures/init`,
            { tramite },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (resp.status === 201) {
            setCreated(true);
            if (files && Object.keys(files).length > 0){
              const response: AxiosResponse<TakingUploadResponse> = await axios.post(
                `${server}/uploads/takings/${resp.data.tramite.codigoTramite}`,
                fd,
                {
                  headers: { Authorization: `Bearer ${token}` },
                  onUploadProgress: (progressEvent) => setProgress(Math.floor(progressEvent.loaded * 100)),
                }
              );
              if (response.status === 200) {
                message.success('Tramite creado exitosamente');
                setProcedure(resp.data.tramite);
                form.resetFields();
                if (selectedProcedure?.pagoPrevio) {
                  form.setFieldsValue({
                    banco: banks
                      ? banks?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id))[0].id ||
                        banks[0].id
                      : 1,
                  });
                }
                setFiles(null);
                history.push('/dashboard/bandejas');
              }
            }
            else{
              message.success('Tramite creado exitosamente');
                setProcedure(resp.data.tramite);
                form.resetFields();
                if (selectedProcedure?.pagoPrevio) {
                  form.setFieldsValue({
                    banco: banks
                      ? banks?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id))[0].id ||
                        banks[0].id
                      : 1,
                  });
                }
                setFiles(null);
                history.push('/dashboard/bandejas');
            }
          }
        } catch (e) {
          message.error(
            e.response && (e.response.data.message as string).includes('referencia')
              ? 'La referencia bancaria ya ha sido utilizada.'
              : 'Error en el servidor'
          );
        } finally {
          setLoading(false);
        }
      }
    } else {
      message.error('Debe subir los archivos requeridos en los recaudos.');
    }
  };

  const handleBankChange = (banco) => {
    form.setFieldsValue({
      banco,
    });
  };

  const handleFiles = (e, name: string, i: number) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      const blob = (file as File).slice(0, (file as File).size, file.type);
      setFiles({
        ...files,
        [name]: new File([blob], `${name}.${file.name.split('.')[file.name.split('.').length - 1]}`, { type: file.type }),
      });
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        if (file.type.includes('image')) recaudosRef.current[i]?.current?.setAttribute('src', e.target?.result?.toString() || '');
        else if (file.type.includes('excel')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/excel.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else if (file.type.includes('pdf')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/pdf.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../assets/images/file.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        }
      };
    }
  };

  const deleteFile = (name: string, i: number) => {
    const _files = Object.assign({}, files);
    delete _files[name];
    setFiles(_files);
    recaudosRef.current[i] = createRef();
  };

  const disabledDate = (current) => current > moment().endOf('day');

  const columns: any = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left',
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta',
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular',
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    },
  ];

  const estateColumns = [
    {
      title: 'Código Catastral',
      dataIndex: 'codCatastral',
      key: 'codCatastral',
    },
    {
      title: 'Area (m²)',
      dataIndex: 'metrosConstruccion',
      key: 'metrosConstruccion',
    },
  ];
  return selectedProcedure ? (
    <Card
      style={{ height: '100%', marginLeft: 2 }}
      title={selectedProcedure.titulo.toUpperCase()}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {actualProcedure?.estado === 'encorreccion' && observations ? (
        <Card
          title={
            <Typography.Text strong>
              <b style={{ color: 'red', marginRight: 2 }}>*</b>
              Observaciones
            </Typography.Text>
          }
          style={{ width: 300, marginTop: 20 }}
        >
          {observations}
        </Card>
      ) : null}
      <Form
        scrollToFirstError
        onFinish={handleSubmit}
        form={form}
        layout='vertical'
        style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
      >
        {selectedProcedure.secciones?.map((s, i) => (
          <React.Fragment key={s.id}>
            <Divider orientation='left' style={{ marginLeft: -30 }}>
              <Typography.Title level={4}>{s.nombre}</Typography.Title>
            </Divider>
            <Row gutter={24}>
              {getFormItem(
                s.campos
                  .filter((c) => c.estado === 'iniciado' || c.estado === 'enproceso')
                  .sort((a, b) => {
                    if (a.orden > b.orden) return 1;
                    if (b.orden > a.orden) return -1;
                    return 0;
                  }) || [],
                i
              )}
            </Row>
          </React.Fragment>
        ))}
        {selectedProcedure.recaudos.length > 0 && (
          <React.Fragment>
            <Divider orientation='left' style={{ marginLeft: -30 }}>
              <Typography.Title level={4}>Recaudos</Typography.Title>
            </Divider>
            <List
              style={{ marginBottom: 20, width: '100%' }}
              bordered
              dataSource={selectedProcedure.recaudos.filter((field, index, array) => array.findIndex((f) => f.id === field.id) === index)}
              size='small'
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text strong>
                    {item.nombreCompleto}
                    {
                      <Typography.Text strong style={{ color: item.fisico ? '#ff4d4f' : '#1890ff' }}>
                        &nbsp;{item.fisico ? 'FISICO' : 'DIGITAL'}
                      </Typography.Text>
                    }
                    {item.planilla && (
                      <Button type='link' icon={<CloudDownloadOutlined />} target='_blank' href={item.planilla}>
                        Descargar Anexo
                      </Button>
                    )}
                  </Typography.Text>
                </List.Item>
              )}
            />
            {selectedProcedure.recaudos.filter((r) => !r.fisico).length > 0 && (
              <Divider orientation='left' style={{ marginLeft: -30 }}>
                <Typography.Title level={4}>Archivos a Subir</Typography.Title>
              </Divider>
            )}
            {selectedProcedure.recaudos.filter((r) => !r.fisico).length > 0 && (
              <Row gutter={24}>
                {selectedProcedure.recaudos
                  .sort((a, b) => (a.obligatorio === b.obligatorio ? 0 : a.obligatorio ? -1 : 1))
                  .filter((r) => !r.fisico).filter((field, index, array) => array.findIndex((f) => f.id === field.id) === index)
                  .map((r, i) => {
                    const ref = React.createRef<HTMLInputElement>();
                    return (files && !files[r.nombreCorto]) || !files ? (
                      <Col
                        key={r.id}
                        xs={24}
                        xl={6}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          marginBottom: 20,
                        }}
                      >
                        <input
                          hidden
                          ref={ref}
                          type='file'
                          accept={r.extension}
                          onChange={(e) => handleFiles(e, r.nombreCorto, i)}
                        />
                        <Button type='primary' style={{ marginBottom: 10 }} onClick={() => ref.current?.click()}>
                          Subir Archivo
                        </Button>
                        <Typography.Text strong style={{ textAlign: 'center' }}>
                          {r.nombreCompleto}
                          {/* &nbsp;{r.obligatorio ? <span style={{ color: 'red' }}>*</span> : ''} */}
                        </Typography.Text>
                      </Col>
                    ) : (
                      <Col xs={24} xl={6} key={r.id}>
                        <Card
                          cover={<img alt='recaudo' ref={recaudosRef.current[i]} />}
                          bodyStyle={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        >
                          <Typography.Text strong style={{ textAlign: 'center' }}>
                            {r.nombreCompleto}
                          </Typography.Text>
                          <Button
                            type='link'
                            onClick={() => deleteFile(r.nombreCorto, i)}
                            style={{ textAlign: 'right', width: '100%', color: 'red' }}
                          >
                            Eliminar
                            <DeleteOutlined />
                          </Button>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            )}
          </React.Fragment>
        )}
        {selectedProcedure.pagoPrevio && (
          <React.Fragment>
            {!selectedProcedure.necesitaCodCat && !idProceso && (
              <React.Fragment>
                <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
                  <Typography.Title level={4}>Datos de Pago</Typography.Title>
                </Divider>
                <Typography.Text strong type='danger'>
                  El tramite tiene un costo fijo de Bs.{' '}
                  {formatCurrency(
                    solvenciaRRICheckbox && parseInt(idTramite) === 16 ? selectedProcedure.costo * 2 : selectedProcedure.costo
                  )}
                  . El monto de la transferencia debe coincidir exactamente con el costo del tramite y debe ser realizada desde el
                  mismo banco.
                </Typography.Text>
                <Table
                  bordered
                  style={{ margin: '20px 0px' }}
                  pagination={false}
                  columns={columns}
                  dataSource={selectedInstitution?.cuentasBancarias?.map((c) => ({
                    ...c,
                    documento: `${c.rif ? 'RIF: ' : 'Cedula: '}${c.rif || c.cedula}`,
                    banco: banks?.find((b) => b.id === c.banco)?.nombre,
                    key: c.id,
                  }))}
                />
                <Row gutter={24}>
                  <Col xs={24} xl={8}>
                    <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
                      {banks ? (
                        <Select onChange={handleBankChange} placeholder='Banco'>
                          {banks
                            ?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id))
                            .map((b, i) => (
                              <Select.Option key={`b-${b.id}`} value={b.id}>
                                {b.nombre}
                              </Select.Option>
                            ))}
                        </Select>
                      ) : (
                        <div></div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      label='Numero de Referencia'
                      name='referencia'
                      rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}
                    >
                      <Input placeholder='Referencia' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      label='Fecha de Pago'
                      name='fecha'
                      rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
                    >
                      <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} disabledDate={disabledDate} />
                    </Form.Item>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {selectedProcedure.necesitaCodCat && (
              <React.Fragment>
                <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
                  <Typography.Title level={4}>Datos del Inmueble</Typography.Title>
                </Divider>
                <Row gutter={24}>
                  {!realEstate && (
                    <React.Fragment>
                      <Typography.Text strong>
                        Este tramite requiere que se ingrese el código catastral del inmueble para calcular las tarifas basadas en
                        los metros cuadrados de construcción de dicho inmueble.
                      </Typography.Text>
                      <Col xs={24} xl={8}>
                        <Form.Item
                          label='Código Catastral del Inmueble'
                          name='codCat'
                          rules={[{ required: true, message: 'Debe ingresar el código catastral del inmueble' }]}
                        >
                          <Input.Search placeholder='Código Catastral' enterButton onSearch={searchEstate} />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                  )}
                  {realEstate && (
                    <React.Fragment>
                      <Col span={24}>
                        <Table
                          bordered
                          style={{ margin: '20px 0px' }}
                          pagination={false}
                          columns={estateColumns}
                          dataSource={[realEstate]}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
                {realEstate && (
                  <React.Fragment>
                    <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
                      <Typography.Title level={4}>Datos de Pago</Typography.Title>
                    </Divider>
                    <Typography.Text strong type='danger'>
                      El tramite tiene un costo por metro cuadrado de área de Bs.{' '}
                      {formatCurrency(solvenciaRRICheckbox ? selectedProcedure.costo * 2 : selectedProcedure.costo)}. Por lo cual,
                      debido a que el inmueble posee un área de &nbsp;
                      {realEstate.metrosConstruccion}m², el monto total a pagar es de Bs.{' '}
                      {formatCurrency(
                        (solvenciaRRICheckbox ? selectedProcedure.costo * 2 : selectedProcedure.costo) *
                          realEstate.metrosConstruccion
                      )}
                      . El monto de la transferencia debe coincidir exactamente con el costo del tramite y debe ser realizada
                      desde el mismo banco.
                    </Typography.Text>
                    <Table
                      bordered
                      style={{ margin: '20px 0px' }}
                      pagination={false}
                      columns={columns}
                      dataSource={selectedInstitution?.cuentasBancarias?.map((c) => ({
                        ...c,
                        documento: `${c.rif ? 'RIF: ' : 'Cedula: '}${c.rif || c.cedula}`,
                        banco: banks?.find((b) => b.id === c.banco)?.nombre,
                        key: c.id,
                      }))}
                    />
                  </React.Fragment>
                )}
                {realEstate && (
                  <Row gutter={24}>
                    <Col xs={24} xl={8}>
                      <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
                        {banks ? (
                          <Select onChange={handleBankChange} placeholder='Banco'>
                            {banks
                              ?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id))
                              .map((b, i) => (
                                <Select.Option key={`b-${b.id}`} value={b.id}>
                                  {b.nombre}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <div></div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item
                        label='Numero de Referencia'
                        name='referencia'
                        rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}
                      >
                        <Input placeholder='Referencia' />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item
                        label='Fecha de Pago'
                        name='fecha'
                        rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
                      >
                        <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} disabledDate={disabledDate} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {!selectedProcedure.pagoPrevio && selectedProcedure.necesitaCodCat && (
          <React.Fragment>
            <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
              <Typography.Title level={4}>Datos del Inmueble</Typography.Title>
            </Divider>
            <Row gutter={24}>
              <React.Fragment>
                <Typography.Text strong>
                  Este tramite requiere que se ingrese el código catastral del inmueble para calcular las tarifas basadas en los
                  metros cuadrados de construcción de dicho inmueble.
                </Typography.Text>
                <Col xs={24} xl={8}>
                  <Form.Item
                    label='Código Catastral del Inmueble'
                    name='codCat'
                    rules={[{ required: true, message: 'Debe ingresar el código catastral del inmueble' }]}
                  >
                    <Input.Search placeholder='Código Catastral' enterButton onSearch={searchEstate} />
                  </Form.Item>
                </Col>
              </React.Fragment>
              {realEstate && (
                <React.Fragment>
                  <Col span={24}>
                    <Table
                      bordered
                      style={{ margin: '20px 0px' }}
                      pagination={false}
                      columns={estateColumns}
                      dataSource={[realEstate]}
                    />
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </React.Fragment>
        )}
      </Form>
      <Modal
        centered
        title={!created ? 'Procesando tramite...' : 'Tramite creado'}
        visible={loading}
        onOk={() => {
          setLoading(false);
          setProgress(0);
        }}
        closable={created}
        footer={created ? undefined : false}
        style={{ textAlign: 'center' }}
        onCancel={() => {
          setLoading(false);
          setProgress(0);
        }}
      >
        {selectedProcedure.recaudos.length > 0 && <Progress percent={progress} />}
        {progress < 100 && selectedProcedure.recaudos.filter((r) => !r.fisico).length > 0 && (
          <Typography.Text strong>Subiendo recaudos...</Typography.Text>
        )}
        {progress >= 100 && selectedProcedure.recaudos.filter((r) => !r.fisico).length > 0 && (
          <Typography.Text strong>Recaudos subidos exitosamente!</Typography.Text>
        )}
        {selectedProcedure.recaudos.filter((r) => !r.fisico).length > 0 && <br />}
        {((selectedProcedure.recaudos.filter((r) => !r.fisico).length === 0 && !created) ||
          (selectedProcedure.recaudos.filter((r) => !r.fisico).length > 0 && progress >= 100 && !created)) && (
          <Typography.Text strong>Creando tramite...</Typography.Text>
        )}
        {created && <Typography.Text strong>Tramite creado exitosamente!</Typography.Text>}
      </Modal>
      {idProceso ? (
        <Button loading={loading} type='primary' onClick={form.submit}>
          Corregir Tramite
        </Button>
      ) : (
        <Button loading={loading} type='primary' onClick={form.submit}>
          Generar Tramite
        </Button>
      )}
    </Card>
  ) : null;
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  institutions: state.inst.institutions,
  banks: state.bk.banks,
  token: state.auth.token,
  auth: state.auth,
  prcd: state.prcd,
});

const ConnectedFormGenerator = connect(mapStateToProps, { setProcedure, updateProcedure })(FormGenerator);

interface FormGeneratorProps {
  institutions: Institucion[] | null;
  banks: Banco[] | null;
  token: string | null;
  auth: States.Auth;
  thm: States.ThemeColors;
  setProcedure: Function;
  prcd: States.Procedures;
  updateProcedure: (data: any, token: string) => Promise<void>;
}

interface TakingUploadResponse {
  recaudos: string[];
}

export default ConnectedFormGenerator;
