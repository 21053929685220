import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fecthReceipts = async (token, id) => {
  try {
    const response = await axios.get(`${server}/cashier/${id}/receipts`,{ headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const generateCertificade = async (data, renovacion, type, token) => {
  try {
    const datos = [{ datos: {...data, renovacion} }];
    const response = await axios.post(`${server}/receipt/${type}/generate`, {datos}, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}