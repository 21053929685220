import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { State, States, Taxpayer, Institucion } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import {
  Form,
  Card,
  Divider,
  Typography,
  Row,
  Col,
  message,
  Descriptions,
  Button,
  Select,
  Modal,
  Spin,
  Input,
  DatePicker,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { setProcedure } from '../../redux/actions/procedures';
import { useHistory } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import '../../assets/css/components/FormGenerator.css';
import { Utils } from '../../utils/validators';
import MultipleTransactions from './components/MultipleTransactions';
import { cloneDeep } from 'lodash';
const server = process.env.REACT_APP_SERVER_URL;

const LicenseLiqueurForm: React.FC<LicenseLiqueurFormProps> = ({ thm, auth, banks, institutions, setProcedure, petro }) => {
  const [taxpayer, setTaxpayer] = useState<Taxpayer | null>(null);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('0');
  const [selectedTaxpayer, setSelectedTaxpayer] = useState<Sucursal | null>(null);
  const [created, setCreated] = useState<boolean>(false);
  const [tipoDocumento, setTipoDocumento] = useState<string>('J');
  const [documento, setDocumento] = useState<string | undefined>();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [form] = Form.useForm();
  const [data, setData] = useState<any>({});

  const selectedInst = institutions?.find((i) => i.id === 9);
  const procedure = selectedInst?.tramitesDisponibles?.find((t) => t.id === 45);

  const formatNumber = (s: string) => `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6)}`;
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const getRims = async () => {
    if (documento && typeof +documento === 'number' && !Number.isNaN(+documento)) {
      try {
        setLoading(true);
        const response = await axios.get<{ contribuyentes: Taxpayer[] }>(
          `${server}/settlements/search/taxPayer?doc=${documento}&pref=${tipoDocumento}`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        setTaxpayer(response.data.contribuyentes[0]);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al obtener referencias de inmuebles');
      } finally {
        setLoading(false);
      }
    } else return message.error('Debe ingresar un documento valido');
  };

  const handleBankChange = (banco) => {
    form.setFieldsValue({
      banco,
    });
  };

  useEffect(() => {
    if (taxpayer?.usuarios && taxpayer?.usuarios.length > 0) form.setFieldsValue({ usuario: taxpayer.usuarios[0].id });
    // eslint-disable-next-line
  }, [taxpayer]);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      Object.keys(values).forEach((k) => {
        if (moment.isMoment(values[k])) {
          values[k] = values[k].toISOString();
        }
      });

      const pagos: Array<any> = Array.isArray(form.getFieldValue('pagos')) ? cloneDeep(form.getFieldValue('pagos')) || [] : [];

      pagos
        .filter((p) => p)
        .forEach((p) => {
          Object.keys(p).forEach((k) => {
            if (moment.isMoment(p[k])) {
              p[k] = p[k].toISOString();
            }
          });
        });

      if (pagos.map((p) => p.costo).reduce((c, v) => c + v, 0) < Number((procedure?.petro ?? 0) * petro)) {
        return message.error('El monto a pagar debe coincidir con el costo del tramite');
      }

      const tramite = {
        datos: {
          ...values,
        },
        pago: pagos[0], // pago único
        tipoTramite: 45,
      };

      delete tramite.datos.pagos;

      console.log({ tramite });
      const resp = await axios.post(
        `${server}/procedures/init`,
        { tramite },
        { headers: { Authorization: `Bearer ${auth?.token}` } }
      );
      message.success('Tramite creado exitosamente');
      setProcedure(resp.data.tramite);
      setCreated(true);
      form.resetFields();
      history.push('/dashboard/bandejas');
    } catch (e) {
      message.error(e.response && e.response?.data?.message ? e.response?.data?.message : 'Error en el servidor');
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    // tipoTramite: 29,
    // contribuyente: auth?.user?.contribuyente,
    // denomComercial: selectedTaxpayer?.denomComercial,
    // representanteLegal: selectedTaxpayer?.nombreRepresentante,
    // referenciaMunicipal: selectedTaxpayer?.referenciaMunicipal,
    // telefonoMovil: selectedTaxpayer?.telefono,
    // telefono: selectedTaxpayer?.telefono,
    // documento: taxpayer?.documento,
    tipoDocumento: taxpayer?.tipoDocumento || 'J',
    tipoDocumentoRepresentante: 'V',
    // poseiaLicencia: false,
    // razonSocial: taxpayer?.razonSocial,
    metodoPago: 'TRANSFERENCIA',
  };

  const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  const rifAddon = (
    <Form.Item noStyle name='tipoDocumento'>
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const cedulaAddon = (
    <Form.Item noStyle name='tipoDocumentoRepresentante'>
      <Select>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='V'>V</Select.Option>
      </Select>
    </Form.Item>
  );

  const roundCalc = (number: number): number => +number.toFixed(2);

  const calcTotalIngresado = () =>
    data.pagos?.reduce((acc, p) => {
      if (!p) return 0;
      // if (p.metodoPago === 'EFECTIVO PESO') {
      //   const amount = +p.costo * +pesoBS;
      //   return acc + amount;
      // }
      // if (p.metodoPago === 'EFECTIVO DOLAR') {
      //   const amount = +p.costo * +dolar;
      //   return acc + amount;
      // }
      // if (p.metodoPago === 'EFECTIVO EURO') {
      //   const amount = +p.costo * +euro;
      //   return acc + amount;
      // }
      return acc + +p.costo;
    }, 0) || 0;

  const calcDiffSign = (monto, montoPetro) =>
    roundCalc(calcTotalIngresado()) >=
    roundCalc(+parseFloat(monto || ((+(montoPetro || 0) * petro) as unknown as string) || '0').toFixed(2));

  const calcDiff = (monto, montoPetro) =>
    Math.abs(
      calcTotalIngresado() -
        roundCalc(+parseFloat(monto || ((+(montoPetro || 0) * petro) as unknown as string) || '0').toFixed(2))
    );

  return (
    <Card
      style={{ height: '100%' }}
      title={'Licencia de Licores'}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form
        scrollToFirstError
        onFinish={handleSubmit}
        initialValues={initialValues}
        form={form}
        layout='vertical'
        style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
        onValuesChange={(c, v) => {
          form.setFieldsValue(v);
          setData(v);
        }}
      >
        <Divider orientation='left' style={{ marginLeft: -30 }}>
          <Typography.Title level={4}>Datos del Solicitante</Typography.Title>
        </Divider>
        <Row gutter={24}>
          <Col xs={24} xl={7}>
            <Form.Item label='Razón Social' name='razonSocial' rules={[{ required: true, message: 'Este compo es requerido' }]}>
              <Input placeholder='Razón Social' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item
              label='RIF'
              name='documento'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
            >
              <Input placeholder='Documento de Identidad' addonBefore={rifAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item
              label='Representante Legal'
              name='representanteLegal'
              rules={[{ required: true, message: 'Debe ingresar una Referencia Municipal' }]}
            >
              <Input placeholder='Representante Legal' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={24}>
            <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Input.TextArea placeholder='Dirección' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='Ciudad' name='ciudad' rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Input placeholder='Ciudad' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='Municipio' name='municipio' rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Input placeholder='Municipio' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='Estado' name='estado' rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Input placeholder='estado' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={10}>
            <Form.Item label='Anexo A' name='anexoa' rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Input placeholder='Anexo A' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={10}>
            <Form.Item
              label='clasificación del Expendio'
              name='clasificacionExpendio'
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Input placeholder='clasificación del Expendio' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={10}>
            <Form.Item
              label='Tipo de Licencia'
              name='tipoLicencia'
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Select>
                <Select.Option value={'PERMANENTE'}>PERMANENTE</Select.Option>
                <Select.Option value={'TEMPORAL'}>TEMPORAL</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Divider orientation='left' style={{ marginLeft: -30 }}>
            <Typography.Title level={4}>Horario de Trabajo</Typography.Title>
          </Divider>

          <Col xs={24} xl={5}>
            <Form.Item
              label='Desde Dia'
              name={['horarioTrabajo', 'startDay']}
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Select>
                {days.map((d) => (
                  <Select.Option value={d}>{d}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} xl={5}>
            <Form.Item
              label='Hasta Dia'
              name={['horarioTrabajo', 'endDay']}
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <Select>
                {days.map((d) => (
                  <Select.Option value={d}>{d}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} xl={5}>
            <Form.Item
              label='Desde Horas'
              name={['horarioTrabajo', 'startHour']}
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <DatePicker.TimePicker format={'hh:mm A'} />
            </Form.Item>
          </Col>

          <Col xs={24} xl={5}>
            <Form.Item
              label='Hasta Horas'
              name={['horarioTrabajo', 'endHour']}
              rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
              <DatePicker.TimePicker format={'hh:mm A'} />
            </Form.Item>
          </Col>

          <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
            <Typography.Title level={4}>Datos de Pago</Typography.Title>
          </Divider>
          <Typography.Text strong type='danger'>
            El tramite tiene un costo de bs {formatCurrency(Number(procedure?.petro ?? 0) * Number(petro ?? 0))} equivalente a{' '}
            {formatCurrency(procedure?.petro ?? 0)} MMV. El monto de la transferencia debe coincidir exactamente con el costo del
            tramite y debe ser realizada desde el mismo banco.
          </Typography.Text>

          <MultipleTransactions selectedInstitution={selectedInst} form={form} data={data} disableMultipleTransaction />

          <Row>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Total Ingresado</Typography.Title>
            </Divider>
            <Col xl={24} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item
                  label='Total Ingresado'
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  Bs. {console.log('data: ', data) + '' === 'undefined' && formatCurrency(calcTotalIngresado())}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Monto Restante</Typography.Title>
            </Divider>
            <Col xl={24} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item
                  label={calcDiffSign((procedure?.petro ?? 0) * petro, petro) ? 'Total a Favor' : 'Total Restante'}
                  style={{
                    color: calcDiffSign((procedure?.petro ?? 0) * petro, petro) ? '#86E92B' : 'red',
                    fontSize: '1.2rem',
                  }}
                >
                  Bs. {formatCurrency(calcDiff((procedure?.petro ?? 0) * petro, petro))}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          <Col span={24}>
            <Button style={{ marginTop: 16 }} loading={loading} type='primary' htmlType='submit'>
              procesar Tramite
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        footer={null}
        centered
        title={!created ? 'Procesando tramite...' : 'Tramite creado'}
        visible={loading}
        onOk={() => {
          setLoading(false);
        }}
      >
        <Row justify='center' align='middle'>
          <Spin tip='Creando tramite...' indicator={<LoadingOutlined style={{ fontSize: 40, color: thm.primaryColor }} />} />
        </Row>
        {created && <Typography.Text strong>Tramite creado exitosamente!</Typography.Text>}
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  auth: state.auth,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  petro: state.coin.petro,
});

export default connect(mapStateToProps, { setProcedure })(LicenseLiqueurForm);

interface LicenseLiqueurFormProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  institutions: Institucion[] | null;
  setProcedure: Function;
  banks: States.Banks['banks'];
  petro: States.Coins['petro'];
}

interface Sucursal {
  actualizado: boolean;
  denomComercial: string;
  email: string;
  fechaAprobacion: string;
  id: string;
  nombreRepresentante: string;
  referenciaMunicipal: string;
  telefono: string;
}
