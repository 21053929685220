import { States } from "sigt";
import { AEType, SET_AES } from "../actions/actionsTypes";

const initialState: States.AES = {
  activities: []
};

export default (state: States.AES = initialState, action: AEType) => {
  switch(action.type) {
    case SET_AES: 
      return {  ...state, activities: action.payload };
    default: return state;
  }
};