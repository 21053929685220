import { SET_BANKS, BankTypes } from '../actions/actionsTypes';
import { States } from 'sigt';

const initialState: States.Banks = {
  banks: []
}

export default (state: States.Banks = initialState, action: BankTypes) => {
  switch(action.type) {
    case SET_BANKS: 
      return { ...state, banks: action.payload }
    default: return state;
  }
}