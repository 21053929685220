import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Form, Input, Tooltip, Button, Descriptions } from 'antd';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import moment from 'moment';
import handlingMessage from '../../utils/handlingMessage';
import { getProperty } from '../../services/estates';
import { useLocation } from 'react-router';
import { Utils } from '../../utils/validators';
import Petro from '../Icons/Petro';


const formatCurrency = (number: number | string) => { 
  number = (typeof number !== 'number') ? parseFloat(number) : number
  return new Intl.NumberFormat('de-DE').format(Math.round(((number) + Number.EPSILON) * 100) / 100)
};

const RegisterProperty : React.FC<RegisterPropertyProps> = ({thm, auth, prsh, coin}) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [property, setProperty] = useState<any>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  const searchProperty = async() => {
    setDisabled(true);
    const values = await form.validateFields();
    handlingMessage({
      action: () => getProperty(values.codCatSearch, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if(data.status === 200){
          const inmueble = { ...data.inmueble };
          inmueble.avaluos = inmueble.avaluos.map((a) => ({...a, avaluo: +Number(a.avaluo * coin.petro).toFixed(2) }))
          setProperty(inmueble);
        }
        setDisabled(false);
      }
   });
  }

  return (
    <Card style={{ height: '100%' }} title='Consultar Inmueble' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
     headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form form={form}  onFinish={searchProperty} layout='vertical'>
        <Row style={{paddingLeft: 16}} gutter={16}> 
          <Col span={12}>
            <Form.Item label='Código de identificación del Inmueble' name='codCatSearch'  normalize={Utils.normalize.toUpper} rules={[{ required: true, message: 'Debe ingresar el Código de identificación del Inmueble' }]}>
              <Input placeholder='Código de identificación del Inmueble' />
            </Form.Item>
          </Col>
          <Col span={12}  style={{alignSelf:'center', paddingTop:'15px'}}>
            <Button type='primary' disabled={disabled} htmlType='submit'>Buscar</Button>
          </Col>   
        </Row>
      </Form>
      {property && <>
        {(property.propietarios || property.propietarioRim) && <Descriptions title='Contribuyentes enlazados' column={3} layout='vertical' bordered>
          {property?.propietarios?.map((p) => (<>
            <Descriptions.Item label='Documento de Identidad'>{p?.rif || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Referencia de inmueble'>{p?.rim || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Razón Social'>{p?.razonSocial || 'N/A'}</Descriptions.Item>
          </>))}
          {property?.propietarioRim && <>
            <Descriptions.Item label='Documento de Identidad'>{property?.propietarioRim?.rif || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Referencia de inmueble'>{property?.propietarioRim?.rim || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label='Razón Social'>{property?.propietarioRim?.razonSocial || 'N/A'}</Descriptions.Item>
          </>}
        </Descriptions>}
        <Descriptions title='Datos del inmueble' column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
          <Descriptions.Item label='Codigo Catastral'>{property.codigoCatastral}</Descriptions.Item>
          <Descriptions.Item label='Tipo de Inmueble'>{property.tipoInmueble || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Condición'>{property.relacion || 'NO DEFINIDO'}</Descriptions.Item>
          <Descriptions.Item label='Metros de Construccion'>{property.metrosConstruccion || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Metros de Terreno'>{property.metrosTerreno || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Dirección' span={width > 992 ? 2 : 1}>{property.direccion || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Parroquia'>{property?.idParroquia ? prsh.parish.find(p => p.id === property.idParroquia)?.nombre : 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Fecha inicio' span={width > 992 ? 2 : 1}>
            {property.fechaInicio ? moment(property.fechaInicio).utcOffset(4).format('DD/MM/YYYY') : 'N/A'}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout='vertical' bordered>
          <Descriptions.Item label='Avaluos' span={3}>
            {<Descriptions bordered layout={width > 480 ? 'horizontal' : 'vertical'} >
              {property.avaluos?.map(e => <Descriptions.Item key={e.anio} label={e.anio} span={2}><Tooltip title={`${formatCurrency(+(Number(+e.avaluo * coin.petro).toFixed(2)))} Bs.` } ><span style={{cursor: 'pointer'}} >{e.avaluo}<Petro style={{ marginLeft: 8 }}/></span></Tooltip></Descriptions.Item>)}
            </Descriptions>}
          </Descriptions.Item>
        </Descriptions>
      </>}
    </Card>
  )
}

interface RegisterPropertyProps {
  auth: States.Auth
  thm: States.ThemeColors
  prsh: States.Parish
  coin: States.Coins
}

const mapStateToProps = ({ thm, auth, prsh, coin }: State) => ({ thm, auth, prsh, coin })

export default connect(mapStateToProps)(RegisterProperty);