import React, { useEffect, useState, useRef } from 'react';
import { EditOutlined, CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Input, Button, Table, Tooltip, Form, InputNumber, Col, Row } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { connect } from 'react-redux';
import { State, States } from 'sigt';
import handlingMessage from '../../utils/handlingMessage';
import { useWindowDimensions } from '../../utils/hooks';
import Highlighter from 'react-highlight-words';
import { fetchActivities, updateActivities } from '../../services/activities';
import Petro from '../Icons/Petro';

const EditableCell: React.FC<EditableCellProps | any> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber type='number' min={0} max={50} step={0.1} /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Por favor ingresar ${title}!` }]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableActivities: React.FC<PropsTable> = ({ thm, auth }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [editingId, setEditingId] = useState('');
  const [activities, setActivities] = useState<Item[]>([]);
  const [auxActivitiesId, setAuxActivitiesId] = useState<number[]>([]);
  const isEditing = (record) => record.id === editingId;
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(false);

  useEffect(() => {
    setLoading(activities.length > 0 ? false : true);
  }, [activities]);

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns: any = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      editable: false,
      key: 'codigo',
      ...getColumnSearchProps('codigo'),
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ...getColumnSearchProps('descripcion'),
    },
    {
      title: 'Alicuota',
      dataIndex: 'alicuota',
      key: 'alicuota',
      editable: true,
      align: 'center',
      ...getColumnSearchProps('alicuota'),
      render: (text) => <span>{text}<Petro style={{ width: 15, height: 15, textAlign: 'center', marginLeft:5, marginBottom:5 }}/></span>
    },
    {
      title: 'Mínimo Tributable',
      dataIndex: 'minimoTributable',
      align: 'center',
      key: 'minimoTributable',
      ...getColumnSearchProps('minimoTributable'),
      render: (text) => <span>{text}<Petro style={{ width: 15, height: 15, textAlign: 'center', marginLeft:5, marginBottom:5 }}/></span>
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Tooltip placement='bottom' title='Confirmar'>
              <Button type='link' icon={<CheckOutlined />} onClick={() => save(record.id)} />
            </Tooltip>
            <Tooltip placement='bottom' title='Cancelar'>
              <Button type='link' icon={<CloseOutlined />} onClick={() => setEditingId('')} />
            </Tooltip>
          </span>
        ) : (
          <span>
            <Tooltip placement='bottom' title='Editar'>
              <Button type='link' icon={<EditOutlined />} onClick={() => edit(record)} />
            </Tooltip>
          </span>
        );
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingId(record.id);
  };

  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, []);

  const aux = async () => {
    if (auth.user) {
      const { data } = await fetchActivities(auth.token);
      setActivities(data);
    }
    setLoading(false);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'alicuota' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...activities];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        const values = newData[index];
        setAuxActivitiesId([...auxActivitiesId, values.id]);
        setActivities(newData);
        setEditingId('');
      }
    } catch (errInfo) {
      console.log(errInfo);
    }
  };
  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const handleUpdate = () => {
    setRequest(true);
    const data = activities.filter((item) => auxActivitiesId.includes(item.id));
    handlingMessage({
      action: () => updateActivities(data, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: () => {
        setRequest(false);
      },
    });
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Administrar Alicuotas'
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}
    >
      <Form form={form} component={false}>
        <Table
          components={components}
          style={{ height: '100%' }}
          size='middle'
          loading={loading}
          pagination={{ pageSize: 8 }}
          columns={mergedColumns}
          dataSource={activities}
          rowKey={(row) => row.id}
        />
        <Row>
          <Col>
            <Button type='primary' disabled={loading} loading={request} onClick={handleUpdate}>
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = ({ auth, thm }: State) => ({ auth, thm });

export default connect(mapStateToProps, {})(TableActivities);

interface PropsTable {
  auth: States.Auth;
  thm: States.ThemeColors;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
}

interface Item {
  id: number;
  codigo: string;
  descripcion: string;
  alicuota: number;
  minimoTributable: number;
}
