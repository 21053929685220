import React, { useEffect, useState } from 'react';
import { States, State, Taxpayer } from 'sigt';
import { connect, useSelector } from 'react-redux';
import {
  Card,
  Collapse,
  Table,
  Divider,
  Typography,
  Button,
  Modal,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  message,
  Empty,
  Alert,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import Petro from '../../Icons/Petro';
const server = process.env.REACT_APP_SERVER_URL;

const AmendSettlements: React.FC<AmendSettlementsProps> = ({ thm, branches, auth }) => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const [editing, setEditing] = useState<any>(null);
  const [deleting, setDeleting] = useState<any>(null);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [taxpayer, setTaxpayer] = useState<Taxpayer | null>(null);

  const petro = useSelector((state: State) => state.coin.petro);

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const canChangeStatus = [24, 35, 90];
  const canDelete = [24, 35, 38, 90];

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/correcciones/liquidaciones');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTaxpayer((history.location.state as any)?.contribuyente);
  }, [history.location]);

  useEffect(() => {
    if (editing) {
      const liq = {
        ...editing,
        ramo: editing?.ramo ? editing.ramo.id : null,
        subramo: editing?.subramo ? editing.subramo.id : null,
        fechaLiquidacion: editing?.fechaLiquidacion ? moment(editing?.fechaLiquidacion) : null,
      };
      form.setFieldsValue(liq);
      setData(liq);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [editing]);

  useEffect(() => {
    if (editing?.ramo?.id !== data.ramo) {
      form.setFieldsValue({ subramo: null });
    }
    // eslint-disable-next-line
  }, [data.ramo]);

  const formatNumber = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const getNoiceState = (state: string) => {
    switch (state) {
      case 'ingresardatos':
        return 'VIGENTE';
      case 'validando':
        return 'VALIDANDO';
      case 'finalizado':
        return 'SOLVENTE';
      default:
        return 'VIGENTE';
    }
  };

  const { Panel } = Collapse;

  const columns: ColumnsType<any> = [
    {
      dataIndex: 'fechaLiquidacion',
      title: 'Fecha de Liquidación',
      render: (date) => moment(date).format('DD/MM/YYYY'),
    },
    {
      dataIndex: 'monto',
      title: 'Monto en Bs.',
      render: (number) => (number ? <span>Bs. {formatNumber(number)}</span> : <b>NO PAGADO</b>),
    },
    {
      dataIndex: 'montoPetro',
      title: 'Monto en MMV',
      render: (petros, record) => (
        <span>
          {Number(petros || record.monto / petro).toFixed(8)} <Petro />
        </span>
      ),
    },
    {
      dataIndex: 'ramo',
      title: 'Ramo',
      render: (branch) => <span>{branch?.descripcion}</span>,
      width: '20%',
    },
    {
      dataIndex: 'subramo',
      title: 'Sub-Ramo',
      render: (branchee) => <span>{branchee?.descripcion}</span>,
    },
    {
      dataIndex: 'estado',
      title: 'Estado',
      render: (state) => <b>{getNoiceState(state)}</b>,
    },
    {
      dataIndex: 'id',
      title: 'Acciones',
      render: (_, record) => {
        const showDelete = record.estado === 'ingresardatos' && canDelete.includes(auth.user?.institucion?.cargo?.id || 0);
        return (
          <Row gutter={0}>
            <Col span={12}>
              <Button type='primary' onClick={() => setEditing(record)} icon={<EditFilled />}>
                Editar
              </Button>
            </Col>
            {showDelete && (
              <Col span={12}>
                <Button type='danger' onClick={() => setDeleting(record)} icon={<DeleteFilled />}>
                  Eliminar
                </Button>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const amendSettlement = async (settlement) => {
    const values = await form.validateFields();
    if (
      settlement.estado !== values.estado ||
      settlement.ramo.id !== values.ramo ||
      settlement.subramo.id !== values.subramo ||
      settlement.fechaLiquidacion !== values.fechaLiquidacion.toISOString()
    ) {
      setLoading(true);
      try {
        const liquidacion = {
          ...editing,
          ...values,
          fechaLiquidacion: values.fechaLiquidacion.startOf('day').toISOString(),
        };
        await axios.patch(
          `${server}/settlements/${liquidacion.id}`,
          { liquidacion },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        message.success('Liquidación corregida de manera exitosa');
        const _taxpayer = Object.assign({}, taxpayer);
        if (settlement.rimLiq) {
          const _sucursales = [...(taxpayer?.sucursales ? taxpayer.sucursales : [])];
          const sIndex = _sucursales.findIndex((s) => s.id === liquidacion.sucursal);
          const lIndex = _sucursales[sIndex].liquidaciones.findIndex((l) => l.id === liquidacion.id);
          const ramo = branches.find((b) => b.id === liquidacion.ramo);
          _sucursales[sIndex].liquidaciones[lIndex] = {
            ...liquidacion,
            ramo,
            subramo: ramo?.subramos.find((s) => s.id === liquidacion.subramo),
          };
          _taxpayer.sucursales = _sucursales;
        } else if (_taxpayer?.liquidaciones) {
          const lIndex = _taxpayer.liquidaciones?.findIndex((l) => l.id === liquidacion.id);
          const ramo = branches.find((b) => b.id === liquidacion.ramo);
          const _liquidaciones = cloneDeep(_taxpayer.liquidaciones);
          _liquidaciones[lIndex] = { ...liquidacion, ramo, subramo: ramo?.subramos.find((s) => s.id === liquidacion.subramo) };
          _taxpayer.liquidaciones = _liquidaciones;
        }
        setTaxpayer(_taxpayer);
        setEditing(null);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al corregir liquidación');
      } finally {
        setLoading(false);
      }
    } else {
      message.warn('Debe corregir alguno de los campos de la liquidación para poder corregirla');
    }
  };

  const deleteSettlement = async (settlement) => {
    setLoading(true);
    try {
      await axios.delete(`${server}/settlements/${settlement.id}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Liquidación eliminada de manera exitosa.');
      const _taxpayer = Object.assign({}, taxpayer);
      const _sucursales = [...(taxpayer?.sucursales ? taxpayer.sucursales : [])];
      const sIndex = _sucursales.findIndex((s) => s.id === settlement.sucursal);
      _sucursales[sIndex].liquidaciones = _sucursales[sIndex].liquidaciones.filter((l) => l.id !== settlement.id);
      _taxpayer.sucursales = _sucursales;
      setTaxpayer(_taxpayer);
      setDeleting(null);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al eliminar liquidación');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Corrección de Liquidaciones'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetTaxpayerInfo />}
      {id && (
        <>
          {(taxpayer?.liquidaciones?.length || 0) > 0 ? (
            <>
              <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10 }}>
                <Typography.Title level={4}>Liquidaciones del Contribuyente</Typography.Title>
              </Divider>
              <Table
                pagination={{ pageSize: 5 }}
                bordered
                columns={columns}
                dataSource={taxpayer?.liquidaciones || []}
                rowKey='id'
              />
            </>
          ) : (taxpayer?.sucursales?.length || 0) > 0 ? (
            <>
              <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10 }}>
                <Typography.Title level={4}>Liquidaciones de los Registros Municipales (R.I.M.)</Typography.Title>
              </Divider>
              <Collapse accordion activeKey={activeKey} onChange={(c) => setActiveKey(c as string)}>
                {taxpayer?.sucursales?.map((s) => (
                  <Panel header={`${s.referenciaMunicipal} - ${s.denomComercial}`} key={s.id} forceRender>
                    <Table
                      pagination={{ pageSize: 5 }}
                      bordered
                      columns={columns}
                      dataSource={
                        s.liquidaciones.map((l) => {
                          l.key = l.id;
                          l.sucursal = s.id;
                          l.rimLiq = true;
                          return l;
                        }) || []
                      }
                    />
                  </Panel>
                ))}
              </Collapse>
            </>
          ) : (
            <Card>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Card>
          )}
          <Button
            onClick={() =>
              history.push('/dashboard/correcciones/liquidaciones', {
                contribuyentes: (history.location.state as any)?.contribuyentes,
              })
            }
            style={{ marginTop: 10 }}
          >
            Atrás
          </Button>
        </>
      )}
      <Modal
        visible={!!editing}
        onCancel={() => setEditing(null)}
        title='Corregir Liquidación'
        width={width < 992 ? '100%' : '40%'}
        onOk={() => amendSettlement(editing)}
        okButtonProps={{ icon: <EditFilled />, title: 'Corregir', loading }}
      >
        <Form
          layout='vertical'
          form={form}
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData(v);
          }}
        >
          <Row gutter={24}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Fecha de Liquidación'
                name='fechaLiquidacion'
                rules={[{ required: true, message: 'Debe ingresar la fecha de liquidación' }]}
              >
                <DatePicker
                  placeholder='Fecha de Liquidación'
                  style={{ width: '100%' }}
                  disabled={editing?.estado === 'finalizado' && !canChangeStatus.includes(auth.user?.institucion?.cargo?.id || 0)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Estado'
                name='estado'
                rules={[{ required: true, message: 'Debe ingresar el estado de la liquidación' }]}
              >
                <Select placeholder='Estado' disabled={!canChangeStatus.includes(auth.user?.institucion?.cargo?.id || 0)}>
                  <Select.Option value='ingresardatos'>Vigente</Select.Option>
                  <Select.Option value='finalizado'>Solvente</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Ramo' name='ramo' rules={[{ required: true, message: 'Debe ingresar el ramo' }]}>
                <Select
                  showSearch
                  filterOption={(value: string, option) => option?.title?.includes(value?.toUpperCase())}
                  placeholder='Ramo'
                >
                  {branches.map((b) => (
                    <Select.Option title={b.descripcion.toUpperCase()} value={b.id} key={b.id}>
                      {b.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Sub-Ramo' name='subramo' rules={[{ required: true, message: 'Debe ingresar el sub-ramo' }]}>
                <Select placeholder='Sub-Ramo'>
                  {branches
                    .find((b) => b.id === data.ramo)
                    ?.subramos?.map((sb) => (
                      <Select.Option value={sb.id} key={sb.id}>
                        {sb.descripcion}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={!!deleting}
        onCancel={() => setDeleting(null)}
        title='Eliminar Liquidación'
        width={width < 992 ? '100%' : '40%'}
        onOk={() => deleteSettlement(deleting)}
        okButtonProps={{ icon: <DeleteFilled />, children: 'Eliminar', type: 'danger', loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Row>
          <Col span={24}>
            <Alert
              style={{ fontWeight: 'bolder' }}
              type='error'
              message='ESTA ACCIÓN ES POTENCIALMENTE DESTRUCTIVA Y NO ES REVERSIBLE.'
            />
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, branches: state.brch.branches, auth: state.auth });

export default connect(mapStateToProps)(AmendSettlements);

interface AmendSettlementsProps {
  thm: States.ThemeColors;
  branches: States.Branches['branches'];
  auth: States.Auth;
}
