import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Taxpayer, States, State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { Card, Descriptions, Typography, Row, Col, Collapse, Button, Table, Divider } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import EditContributorForm from '../Forms/EditContributorForm';
import TableTaxes from '../Tables/TableTaxes';
import Petro from '../Icons/Petro';
const server = process.env.REACT_APP_SERVER_URL;

const TaxpayerInfo: React.FC<TaxpayerInfoProps> = ({ thm, parish, auth }) => {
  const [taxpayer, setTaxpayer] = useState<Taxpayer | null>(null);
  const [activeKey, setActiveKey] = useState('0');
  const [rim, setRim] = useState<string | null>(null);
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [contributors, setContributors] = useState<any[]>([]);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const edit = location.pathname.includes('/dashboard/correcciones/editar/contribuyente');
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    if(!(history.location?.state as any)?.contribuyente) history.goBack();
    else setTaxpayer((history.location?.state as any)?.contribuyente);
    setContributors((history.location.state as any)?.contribuyentes)
    // eslint-disable-next-line
  }, []);

  const handleRim = (rim) => {
    setRim(rim);
    setVisible(true);
  }

  const close = () => {
    setVisible(false);
    setRim(null);
  }
  useEffect(() => {
    if(taxpayer){
      let auxContributor = contributors;
      const indexContributor = auxContributor.findIndex(el => el.id === taxpayer.id);
      auxContributor[indexContributor] = taxpayer;
      setContributors(auxContributor); 
    }
    // eslint-disable-next-line
  }, [taxpayer]);

  const handleEditRif = async(data: Taxpayer) => {
    await setTaxpayer({...taxpayer, ...data});
    setVisible(false);
  }

  const handleEditRim = (data) => {
    if(taxpayer?.sucursales){
      const index: number = taxpayer?.sucursales?.findIndex(el => el.id === data?.id);
      const auxTaxpayer = taxpayer;
      if(auxTaxpayer?.sucursales) auxTaxpayer.sucursales[index] = {...auxTaxpayer.sucursales[index], ...data}
      setTaxpayer(auxTaxpayer);
      setVisible(false);
    }
  }

  const formatNumber = (s: string) => `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6)}`;

  const columns: ColumnsType<any> = [
    {
      title: 'Descripción',
      dataIndex: 'descripcion'
    },
    {
      title: 'Desde',
      dataIndex: 'desde',
      render: (date) => <span>{moment(date).format('DD/MM/YYYY')}</span>
    },
    {
      title: 'Minimo Tributable',
      dataIndex: 'minimoTributable',
      render: (min) => <span>{min} <Petro /></span>
    }
  ];

  return (<>
    <Card style={{ height: '100%' }} title='Información del Contribuyente' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'scroll', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <Descriptions title='Datos del Contribuyente' bordered column={{xl:24}}>
        <Descriptions.Item label='Documento de Identidad' span={12}>{taxpayer?.tipoDocumento ? `${taxpayer?.tipoDocumento}-${taxpayer?.documento}` : 'N/A'}</Descriptions.Item>
        <Descriptions.Item label='Razón Social' span={12}>{taxpayer?.razonSocial || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label='Denominación Comercial' span={12}>{taxpayer?.denomComercial || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label='Siglas' span={12}>{taxpayer?.siglas || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label='Tipo de Contribuyente' span={12}>{taxpayer?.tipoContribuyente || 'N/A'}</Descriptions.Item>
        {parseFloat(taxpayer?.creditoFiscal || '0') && 
          <Descriptions.Item label='Credito Fiscal'>Bs. {formatCurrency(parseFloat(taxpayer?.creditoFiscal || '0'))}</Descriptions.Item>}
      </Descriptions>
      <Descriptions title='Ubicación' bordered style={{ marginTop: 15 }} column={{xl:24}}>
        <Descriptions.Item label='Dirección' span={12}>{taxpayer?.direccion || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label='Punto de Referencia' span={12}>{taxpayer?.puntoReferencia || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label='Sector' span={12}>{taxpayer?.sector || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label='Parroquia' span={12}>{taxpayer?.parroquia ? parish.find(p => p.id === taxpayer.parroquia)?.nombre : 'N/A'}</Descriptions.Item>
      </Descriptions>
      {taxpayer?.liquidaciones && taxpayer.liquidaciones.length > 0 && (auth.user?.tipoUsuario !== 4 && auth.user) &&
      <>
        <Divider orientation='left' style={{ marginLeft: -15, marginBottom: 10 }}>
          <Typography.Text strong style={{ color: '#000000' }}>Liquidaciones</Typography.Text>
        </Divider>
        <TableTaxes preloadedData={taxpayer.liquidaciones.map(l => ({
          ...l,
          fecha: { month: moment(l.fechaLiquidacion).utc().format('MMMM'), year: moment(l.fechaLiquidacion).utc().format('YYYY') },
          ramo: l.ramo.descripcion,
          nombreCorto: l.ramo.id === 9 ? 'AE' : l.ramo.id === 8 ? 'IU' : ''
        }))}  />
      </>}
      <Row gutter={16} style={{marginTop:'15px'}} align='middle' justify='end'>
        {edit && <Col><Button type='primary' onClick={() => setVisible(true)}>Editar Contribuyente</Button></Col>}
      </Row>
      {taxpayer?.sucursales && taxpayer?.sucursales?.length > 0 &&
      <React.Fragment>
        <Typography.Title level={4} style={{ fontSize: 16, marginTop: 15 }}>Referencias Municipales (R.I.M.)</Typography.Title>
        <Row>
        <Col span={24} style={{ marginTop: 20 }}>
          <Collapse activeKey={activeKey} onChange={c => setActiveKey(c as string)}>
            {taxpayer?.sucursales?.map(r => 
              <Collapse.Panel header={`${r.referenciaMunicipal} - ${r.denomComercial}`} key={r.id} forceRender>
                <Descriptions bordered column={{xl:24}}>
                  <Descriptions.Item label='Denominación Comercial' span={12}>{r.denomComercial || 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Nombre del Representante' span={12}>{r.nombreRepresentante || 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Fecha de Aprobación' span={12}>{r.fechaAprobacion ? moment(r.fechaAprobacion).format('DD/MM/YYYY') : 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Teléfono' span={12}>{r.telefono ? `+58 ${formatNumber(r.telefono)}` : 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Correo Electrónico' span={12}>{r.email || 'N/A'}</Descriptions.Item>
                  <Descriptions.Item label='Monotributo' span={12}>{r.esMontributo ? 'Activado' : 'Desactivado'}</Descriptions.Item>
                  <Descriptions.Item label='Estado de la Licencia' span={12}>{r.estadoLicencia || 'No Definido'}</Descriptions.Item>
                  <Descriptions.Item label='Fecha de Emisión' span={12}>{r.fechaEmision || 'No Definido'}</Descriptions.Item>
                  <Descriptions.Item label='Fecha de Vencimiento' span={12}>{r.fechaVencimiento || 'No Definido'}</Descriptions.Item>
                  <Descriptions.Item label='Credito Fiscal por Compensación' span={12}>Bs. {formatCurrency(parseFloat(r.creditoFiscal || '0'))}</Descriptions.Item>
                  <Descriptions.Item label='Credito Fiscal por Retención' span={12}>Bs. {formatCurrency(parseFloat(r.creditoFiscalRetencion || '0'))}</Descriptions.Item>
                  <Descriptions.Item label='Tipo de Sociedad' span={12}>{r.tipoSociedad || 'No Definido'}</Descriptions.Item>
                  <Descriptions.Item label='Parroquia' span={12}>{parish.find(e => (e.id === r.parroquia) || (e.nombre === r.parroquia))?.nombre || 'No Definido'}</Descriptions.Item>
                  <Descriptions.Item label='Dirección' span={24}>{r.direccion || 'No Definido'}</Descriptions.Item>
                </Descriptions>
                <Row gutter={16} style={{marginTop:'15px'}} align='middle' justify='end'>
                  {edit && <Col><Button type='primary' onClick={() => handleRim(r.id)}>Editar R.I.M</Button></Col>}
                </Row>
                <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10 }}>
                  <Typography.Title level={4}>Actividades Económicas</Typography.Title>
                </Divider>  
                <Table rowKey='id' bordered pagination={false} columns={columns} dataSource={r.actividadesEconomicas} />
                {(auth.user?.tipoUsuario !== 4 && auth.user) &&
                <>
                  <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10 }}>
                    <Typography.Title level={4}>Liquidaciones</Typography.Title>
                  </Divider>  
                  <TableTaxes preloadedData={r.liquidaciones.map(l => ({
                    ...l,
                    fecha: { month: l.fechaPerteneciente.month, year: l.fechaPerteneciente.year},
                    ramo: l.ramo.descripcion,
                    nombreCorto: l.ramo.id === 9 ? 'AE' : l.ramo.id === 8 ? 'IU' : l.ramo.id === 64 ? 'SM' : ''
                  }))} />
                  <Button href={`${server}/settlements/accountStatement/${taxpayer.id}?tipoContribuyente=${taxpayer.tipoContribuyente}&referencia=${r.referenciaMunicipal}`} 
                    target='_blank' icon={<DownloadOutlined />} type='primary' style={{ marginTop: 10 }}>
                    Estado de Cuenta
                  </Button>
                  <Button href={`${server}/activities/generatePatentDocument?branchId=${r.id}`} 
                    target='_blank' icon={<DownloadOutlined />} type='primary' style={{ marginTop: 10, marginInline: 10 }}>
                    Patente de Actividad Economica
                  </Button>
                </>}
              </Collapse.Panel>)}
            </Collapse>
          </Col>
        </Row>
        <Button onClick={() => edit ? history.push('/dashboard/correcciones/editar/contribuyente', { contribuyentes: contributors }) : history.push('/dashboard/contribuyente/consulta', { contribuyentes: contributors })} style={{ marginTop: 10 }}>
          Atrás
        </Button>
      </React.Fragment>}
    </Card>
    {(visible && edit) && <EditContributorForm modal={visible} rim={rim} taxpayer={taxpayer} close={() => close()} editRif={(data) => handleEditRif(data)} editRim={(data) => handleEditRim(data)} />}</>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, parish: state.prsh.parish, auth: state.auth });

export default connect(mapStateToProps)(TaxpayerInfo);

interface TaxpayerInfoProps {
  thm: States.ThemeColors
  parish: States.Parish['parish']
  auth: States.Auth
}