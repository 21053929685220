import React, { useState, useEffect } from 'react';
import { Modal, InputNumber, Row, Col, Button, Divider, Spin, message } from 'antd';
import { useHistory } from 'react-router';
import handlingMessage from '../utils/handlingMessage';
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { updatePetro } from '../redux/actions/coins';
import axios from 'axios';
import Petro from './Icons/Petro';
const server = process.env.REACT_APP_SERVER_URL;

const Scale: React.FC<ScaleProps> = ({ thm, petro, updatePetro, auth }) => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState<{ [id: string]: boolean }>({});
  const [petroBs, setPetrobs] = useState<number | undefined>();
  const [scales, setScales] = useState<{ id: number; descripcion: string; indicador: string | number }[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (petro) setPetrobs(petro);
  }, [petro]);

  const getScales = async () => {
    try {
      // const response = await axios.get(`${server}/scales`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setScales(/*response.data?.scales*/ []);
      message.success(/*response.data?.message || */ 'Baremo de tarifas obtenido!');
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al obtener tasas y limites');
    }
  };

  const onChangeValue = (id: number, value: number) => {
    const _scales = [...scales];
    const sIndex = _scales.findIndex((e) => e.id === id);
    const _scale = _scales[sIndex];
    _scale.indicador = value;
    _scales[sIndex] = _scale;
    setScales(_scales);
  };

  useEffect(() => {
    getScales();
  }, []);

  const updateValue = async (id: number, indicador: number | string) => {
    setLoading({ ...loading, [id]: true });
    try {
      const response = await axios.put(
        `${server}/scales/${id}`,
        { indicador },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      message.success(response.data?.message || 'Baremo de tarifas obtenido!');
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al obtener tasas y limites');
    } finally {
      setLoading({ ...loading, [id]: false });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    history.goBack();
  };

  const handleSubmit = (coin: string) => {
    setLoading({ ...loading, [coin]: true });
    handlingMessage({
      action: () => updatePetro(petroBs),
      key: `update${coin}`,
      loadingMessage: `Actualizando ${coin}...`,
      cb: () => {
        setLoading({ ...loading, [coin]: false });
      },
    });
  };

  return (
    <React.Fragment>
      <Row style={{ height: '100%', width: '100%' }} justify='center' align='middle'>
        <Petro style={{ width: 300, height: 300, textAlign: 'center' }} />
      </Row>
      <Modal
        width={600}
        bodyStyle={{ height: '30vh', overflowY: 'scroll' }}
        centered
        visible={visible}
        footer={null}
        closable
        maskClosable
        onCancel={handleCancel}
        title='Baremo de tarifas'
      >
        <Divider orientation='left'>Moneda de mayor valor</Divider>
        <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
          <Col>
            <InputNumber
              style={{ width: '100%' }}
              value={petroBs}
              formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
              onChange={(value) => {
                if (value) setPetrobs(value);
              }}
            />
          </Col>
          <Col>
            <Button loading={loading.PETRO} type='primary' icon={<SyncOutlined />} onClick={() => handleSubmit('PETRO')}>
              Actualizar
            </Button>
          </Col>
        </Row>
        {
          scales.length > 0 && (
            <>
              {scales.map((e) => (
                <>
                  <Divider orientation='left'>{e.descripcion}</Divider>
                  <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
                    <Col>
                      <InputNumber
                        min={0}
                        style={{ width: '100%' }}
                        value={typeof e.indicador === 'number' ? e.indicador : parseFloat(e.indicador)}
                        onChange={(value) => {
                          if (value) onChangeValue(e.id, value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Button
                        loading={loading[e.id]}
                        type='primary'
                        icon={<SyncOutlined />}
                        onClick={() => updateValue(e.id, e.indicador)}
                      >
                        Actualizar
                      </Button>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          )
          // : <Spin className='custom-spinner' indicator={<LoadingOutlined style={{ fontSize: 40, color: thm.primaryColor }} spin />} size='large' />
        }
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ petro: state.coin.petro, thm: state.thm });

export default connect(mapStateToProps, { updatePetro })(Scale);

interface ScaleProps {
  auth: States.Auth;
  petro: number;
  thm: States.ThemeColors;
  updatePetro: (number) => Promise<any>;
}
