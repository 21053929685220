import { States } from "sigt";
import { CoinsType, SET_PETRO } from "../actions/actionsTypes";

const initialState: States.Coins = {
  petro: 0
};

export default (state = initialState, action: CoinsType): States.Coins => {
  switch(action.type) {
    case SET_PETRO: return { petro: action.payload }
    default: return state;
  };
};