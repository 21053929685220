import { Institucion, User, Banco, Procedure, Parroquia, Ordenanza, States, Destination, Notificacion, Fine, Liquidacion, Holiday, Taxpayer, AE, Agreement, AgreementPart, Branch, Request, Brand, Support, Wallets, Collections, Inspection, VehicleType, Vehicle  } from "sigt";

/* AUTHENTICATION */ 
export const AUTH_USER_LOGIN = "AUTH_USER_LOGIN";
export const AUTH_USER_LOGOUT = "AUTH_USER_LOGOUT";
export const AUTH_USER_BLOCK = "AUTH_USER_BLOCK";
export const SET_USER = "SET_USER";
export const EDIT_USER = "EDIT_USER";
export const SET_TAXPAYER_INFO = "SET_TAXPAYER_INFO";
export const SET_ID = 'SET_ID';

export interface AuthUserAction {
  type: typeof AUTH_USER_LOGIN;
  payload: { user: User, token: string };
}

export interface SetUserAction {
  type: typeof SET_USER;
  payload: { user: User | null, token?: string | null }
}

export interface AuthLogoutAction {
  type: typeof AUTH_USER_LOGOUT;
}
export interface AuthUserBlockAction {
  type: typeof AUTH_USER_BLOCK;
  payload: string
}
export interface EditUserAction {
  type: typeof EDIT_USER;
  payload: User;
}

export interface SetTaxpayerInfoAction {
  type: typeof SET_TAXPAYER_INFO
  payload: Taxpayer
};

/* PROCEDURES */
export const SET_INSTITUTIONS = 'SET_INSTITUTIONS';
export const SET_PROCEDURES = 'SET_PROCEDURES';
export const SET_PROCEDURE = 'SET_PROCEDURE';
export const CHANGE_PROCEDURE_STATUS = 'CHANGE_PROCEDURE_STATUS';
export const CHANGE_PROCEDURE_DATA = 'CHANGE_PROCEDURE_DATA';
export const CHANGE_PROCEDURE_CERTIFICATE = 'CHANGE_PROCEDURE_CERTIFICATE';
export const SET_FINES = 'SET_FINES';
export const SET_FINE = 'SET_FINE';
export const CHANGE_FINE_STATUS = 'CHANGE_FINE_STATUS';
export const CHANGE_FINE_CERTIFICATE = 'CHANGE_FINE_CERTIFICATE';
export const SET_SUPPORTS = 'SET_SUPPORTS';
export const SET_SUPPORT = 'SET_SUPPORT';
export const CHANGE_SUPPORT_DATA = 'CHANGE_SUPPORT_DATA';

export interface SetSupportAction {
  type: typeof SET_SUPPORT;
  payload: Support;
}

export interface SetSupportsAction {
  type: typeof SET_SUPPORTS;
  payload: Support[];
}


export interface ChangeSupportData {
  type: typeof CHANGE_SUPPORT_DATA
  payload: {
    id: number
    data: any
  }
}

export interface SetInstitutionAction {
  type: typeof SET_INSTITUTIONS;
  payload: Institucion[];
}
export interface SetIdAction {
  type: typeof SET_ID;
  payload: number;
}
export interface SetProcedureAction {
  type: typeof SET_PROCEDURE;
  payload: Procedure;
}

export interface SetProceduresAction {
  type: typeof SET_PROCEDURES;
  payload: Procedure[];
}

export interface SetFinesAction {
  type: typeof SET_FINES;
  payload: Fine[]
}

export interface SetFineAction {
  type: typeof SET_FINE
  payload: Fine
}

export interface ChangeFineStatusAction {
  type: typeof CHANGE_FINE_STATUS
  payload: {
    id: number
    status: string
  }
}

export interface ChangeFineCertificateAction {
  type: typeof CHANGE_FINE_CERTIFICATE
  payload: {
    id: number
    certificate: string
  }
}

export interface ChangeProcedureStatusAction {
  type: typeof CHANGE_PROCEDURE_STATUS
  payload: {
    id: number
    status: string
  }
}

export interface ChangeProcedureData {
  type: typeof CHANGE_PROCEDURE_DATA
  payload: {
    id: number
    data: any
  }
}

export interface ChangeProcedureCertificate {
  type: typeof CHANGE_PROCEDURE_CERTIFICATE
  payload: {
    id: number
    certificate: string
  }
}

/* OFFICIALS */
export const SET_OFFICIALS = 'SET_OFFICIALS';
export const DELETE_OFFICIAL = 'DELETE_OFFICIAL';
export const CREATE_OFFICIAL = 'CREATE_OFFICIAL';
export const EDIT_OFFICIAL = 'EDIT_OFFICIAL';

export interface SetOfficialAction {
  type: typeof SET_OFFICIALS;
  payload: User[];
}

export interface CreateOfficialAction {
  type: typeof CREATE_OFFICIAL;
  payload: User;
}

export interface EditOfficialAction {
  type: typeof EDIT_OFFICIAL;
  payload: User;
}

export interface DeleteOfficialAction {
  type: typeof DELETE_OFFICIAL;
  payload: number;
}


/* COSTOS */
export const SET_COST = 'SET_COST';
export const SET_DESTINATION_COSTS = 'SET_DESTINATION_COSTS';
export const EDIT_DESTINATION_COST = 'EDIT_DESTINATION_COST';
export const CREATE_DESTINATION_COST = 'CREATE_DESTINATION_COSTS';

export interface SetCostAction {
  type: typeof SET_COST;
  payload: any[];
}


export interface SetDestinationCosts {
  type: typeof SET_DESTINATION_COSTS
  payload: Destination[]
}

export interface EditDestinationCost {
  type: typeof EDIT_DESTINATION_COST
  payload: Destination
}

export interface CreateDestinationCost {
  type: typeof CREATE_DESTINATION_COST
  payload: Destination
}

/** BANCOS */
export const SET_BANKS = 'SET_BANKS';

export interface SetBanksAction {
  type: typeof SET_BANKS;
  payload: Banco[]
}

/** PARROQUIAS */
export const SET_PARISHES = 'SET_PARISHES';

export interface SetParishesAction {
  type: typeof SET_PARISHES
  payload: Parroquia[]
};
/* ORDINANCES */
export const SET_ORDINANCES = 'SET_ORDINANCES';
export const DELETE_ORDINANCE = 'DELETE_ORDINANCE';
export const CREATE_ORDINANCE = 'CREATE_ORDINANCE';
export const EDIT_ORDINANCE = 'EDIT_ORDINANCE';

export interface SetOrdinancesAction {
  type: typeof SET_ORDINANCES;
  payload: Ordenanza[];
}

export interface CreateOrdinanceAction {
  type: typeof CREATE_ORDINANCE;
  payload: Ordenanza;
}

export interface EditOrdinanceAction {
  type: typeof EDIT_ORDINANCE;
  payload: Ordenanza;
}

export interface DeleteOrdinanceAction {
  type: typeof DELETE_ORDINANCE;
  payload: number;
}

/** THEME COLOR */
export const SET_PRIMARY_COLOR =  'SET_PRIMARY_COLOR';

export interface  SetPrimaryColorAction {
  type: typeof SET_PRIMARY_COLOR;
  payload: string
}

/** STATS */
export const SET_STATS = 'SET_STATS';

export interface SetStatsAction {
  type: typeof SET_STATS
  payload: States.Stats['stats']
};

/*NOTIFICACIONES*/
//TODO: cambiar el payload al tipo adecuado 
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_AS_READ = "SET_NOTIFICATIONS_AS_READ";
export const USER_NOTIFICATION = "USER_NOTIFICATION";

export interface SendNotificationAction {
    type: typeof SEND_NOTIFICATION,
    payload: Notificacion
};
export interface SetNotificationsAction {
    type: typeof SET_NOTIFICATIONS,
    payload: Notificacion[]
};

export interface SetNotificationsAsReadAction {
    type: typeof SET_NOTIFICATIONS_AS_READ,
    payload: boolean
};

export interface HasNewNotificationsAction {
    type: typeof USER_NOTIFICATION,
    payload: boolean
};

/*SOLICITUDES*/
export const SET_REQUESTS = 'SET_REQUESTS';
export const ADD_REQUEST = 'ADD_REQUEST';
export const PAY_REQUEST = 'PAY_REQUEST';
export const CHANGE_REQ_STATUS = 'CHANGE_REQ_STATUS';
export const REBATE_REQUEST = 'REBATE_REQUEST';

export interface SetRequestsAction {
  type: typeof SET_REQUESTS
  payload: Request[]
}

export interface AddRequestAction {
  type: typeof ADD_REQUEST
  payload: Request
}

export interface PayRequestAction {
  type: typeof PAY_REQUEST
  payload: number
}

export interface ChangeReqStatusAction {
  type: typeof CHANGE_REQ_STATUS
  payload: { status: string, id: number }
}

export interface RebateReqAction {
  type: typeof REBATE_REQUEST
  payload: Request
}

/*IMPUESTOS*/
//TODO: cambiar el payload al tipo adecuado 
export const SET_TAXES = "SET_TAXES";
export const SET_TAX = "SET_TAX";
export const UPDATE_CERT = "UPDATE_CERT";
export const UPDATE_RECIBO = "UPDATE_RECIBO";
export const CHANGE_TAX_DATA = "CHANGE_TAX_DATA";

export interface SetTaxesAction {
    type: typeof SET_TAXES,
    payload: Liquidacion[]
};

export interface SetTaxAction {
  type: typeof SET_TAX,
  payload: Liquidacion
};

export interface UpdateCertAction {
    type: typeof UPDATE_CERT,
    payload: {
      id: number;
      certificado:string;
    }
};

export interface ChangeTaxData {
  type: typeof CHANGE_TAX_DATA
  payload: {
    id: number
    data: any
  }
}

export interface UpdateReciboAction {
  type: typeof UPDATE_RECIBO,
  payload: {
    id: number;
    recibo:string;
  }
};

/* HOLIDAYS */
export const SET_HOLIDAYS = 'SET_HOLIDAYS';
export const DELETE_HOLIDAY = 'DELETE_HOLIDAY';
export const CREATE_HOLIDAY = 'CREATE_HOLIDAY';

export interface SetHolidaysAction {
  type: typeof SET_HOLIDAYS;
  payload: Holiday[];
}

export interface CreateHolidaysAction {
  type: typeof CREATE_HOLIDAY;
  payload: Holiday;
}


export interface DeleteHolidaysAction {
  type: typeof DELETE_HOLIDAY;
  payload: number;
}

// AES
export const SET_AES = 'SET_AES';

export interface SetAESAction {
  type: typeof SET_AES
  payload: AE[]
}

// AGREEMENTS

export const SET_AGREEMENTS = 'SET_AGREEMENTS';
export const PAY_AGREEMENT = 'PAY_AGREEMENT';

export interface SetAgreementsAction {
  type: typeof SET_AGREEMENTS
  payload: Agreement[]
}

export interface PayAgreementAction {
  type: typeof PAY_AGREEMENT
  payload: AgreementPart
}

//BRANCHES

export const SET_BRANCHES = 'SET_BRANCHES';

export interface SetBranchesAction {
  type: typeof SET_BRANCHES;
  payload: Branch[]
}

//VEHICLES 
export const SET_VEHICLES = 'SET_VEHICLES';
export const SET_VEHICLE = 'SET_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';

export interface SetVehiclesAction {
  type: typeof SET_VEHICLES;
  payload: Vehicle[];
}

export interface SetVehicleAction {
  type: typeof SET_VEHICLE;
  payload: Vehicle;
}

export interface DeleteVehicleAction { 
  type: typeof DELETE_VEHICLE;
  payload: number;
}

/*Brands*/
export const SET_BRANDS = 'SET_BRANDS';

export interface SetBrandsAction {
  type: typeof SET_BRANDS;
  payload: Brand[]
}

/*Types*/
export const SET_TYPES = 'SET_TYPES';

export interface SetTypesAction {
  type: typeof SET_TYPES;
  payload: VehicleType[]
}

//COINS
export const SET_PETRO = 'SET_PETRO';

export interface SetPetroAction {
  type: typeof SET_PETRO;
  payload: number;
}

/* CHARGES */
export const SET_WALLETS = 'SET_WALLETS';
export const SET_COLLECTIONS = 'SET_COLLECTIONS';
export const SET_WALLET_USER = 'SET_WALLET_USER';
export const EDIT_COLLECTION = 'EDIT_COLLECTION';
export const SET_INSPECTIONS = 'SET_INSPECTIONS';
export const SET_INSPECTION = 'SET_INSPECITON';
export const UPDATE_INSPECTION = 'UPDATE_INSPECTION';

export interface SetWalletsAction {
  type: typeof SET_WALLETS;
  payload: Wallets[];
}

export interface SetCollectionsAction {
  type: typeof SET_COLLECTIONS
  payload: Collections[]
}

export interface SetWalletUserAction {
  type: typeof SET_WALLET_USER
  payload: {    
    idCartera: number 
    idUsuario: number | null
    nombreCompleto: string | null
    esAr: boolean
  }
}

export interface EditCollecionAction {
  type: typeof EDIT_COLLECTION
  payload: Collections
}

export interface SetInspectionsAction {
  type: typeof SET_INSPECTIONS
  payload: Inspection[]
}

export interface SetInspectionAction {
  type: typeof SET_INSPECTION
  payload: Inspection
}

export interface UpdateInspectionAction {
  type: typeof UPDATE_INSPECTION
  payload: Inspection
}

export type ChargesTypes = SetWalletsAction | SetCollectionsAction | SetWalletUserAction | EditCollecionAction | SetInspectionsAction | SetInspectionAction | UpdateInspectionAction;

export type HolidaysTypes = SetHolidaysAction | DeleteHolidaysAction | CreateHolidaysAction;

export type TaxesTypes = SetTaxesAction | UpdateCertAction| UpdateReciboAction | SetTaxAction | ChangeTaxData | AuthLogoutAction;

export type NotificationTypes = SendNotificationAction | SetNotificationsAction | SetNotificationsAsReadAction | HasNewNotificationsAction | AuthLogoutAction;

export type CostTypes = SetCostAction | SetDestinationCosts | EditDestinationCost | CreateDestinationCost ;

export type AuthActionTypes = AuthUserAction | AuthUserBlockAction | AuthLogoutAction | SetUserAction | EditUserAction | SetTaxpayerInfoAction;

export type OfficialsTypes = SetOfficialAction | DeleteOfficialAction | CreateOfficialAction | EditOfficialAction | AuthLogoutAction;

export type InstActionsTypes = SetInstitutionAction | AuthLogoutAction;

export type ProceduresActionsTypes = SetProceduresAction | SetSupportAction | SetSupportsAction | ChangeSupportData | SetFineAction | SetFinesAction | ChangeFineCertificateAction | ChangeProcedureStatusAction | ChangeFineStatusAction | ChangeProcedureStatusAction | SetProcedureAction | ChangeProcedureData | AuthLogoutAction | ChangeProcedureCertificate;

export type BankTypes = SetBanksAction;

export type ParishTypes = SetParishesAction;

export type ThemeColorType = SetPrimaryColorAction;

export type OrdinancesTypes = SetOrdinancesAction | CreateOrdinanceAction | EditOrdinanceAction | DeleteOrdinanceAction | AuthLogoutAction;

export type StatsType = SetStatsAction | AuthLogoutAction;

export type RequestType = SetRequestsAction | AddRequestAction | PayRequestAction | ChangeReqStatusAction | RebateReqAction | AuthLogoutAction;

export type AEType = SetAESAction;

export type AgreementsType = SetAgreementsAction | PayAgreementAction | AuthLogoutAction;

export type BranchesType = SetBranchesAction;

export type VehiclesType = SetVehicleAction | SetVehiclesAction | DeleteVehicleAction | SetBrandsAction | SetTypesAction | SetIdAction;

export type CoinsType = SetPetroAction;