import React, { CSSProperties } from 'react';

const initialStyle: CSSProperties = {
  width: 15,
  height: 15,
  objectFit: 'contain',
};

const Petro: React.FC<PetroProps> = ({ style = {}, ...props }) => {
  const _styles = {
    ...initialStyle,
    ...style,
  };

  return <img src={require('../../assets/images/bcv-icon.png')} style={_styles} alt='BCV-ICON' {...props} />;
};

export default Petro;

interface PetroProps {
  style?: CSSProperties;
}
