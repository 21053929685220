import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Select,
  Form,
  Input,
  InputNumber,
  Button,
  Divider,
  Typography,
  Upload,
  message,
  Modal,
  Spin,
} from 'antd';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import MutiplePropertyValues from '../Forms/components/MutiplePropertyValues';
import moment from 'moment';
import handlingMessage from '../../utils/handlingMessage';
import { registerProperty, getProperty, editProperty } from '../../services/estates';
import fileImage from '../../assets/images/file.png';
import { useLocation } from 'react-router';
import { Utils } from '../../utils/validators';
import { UploadFile } from 'antd/lib/upload/interface';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

const server = process.env.REACT_APP_SERVER_URL;
const { Dragger } = Upload;

const RegisterProperty: React.FC<RegisterPropertyProps> = ({ thm, auth, prsh, coin }) => {
  const { width } = useWindowDimensions();
  const tipoInmueble = [{ value: 'RESIDENCIAL' }, { value: 'COMERCIAL' }, { value: 'INDUSTRIAL' }];
  const [form] = Form.useForm();
  const [years, setYears] = useState<number[]>([]);
  const [property, setProperty] = useState<any>(null);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);

  const edit = location.pathname.includes('/dashboard/inmueblesUrbanos/editar');
  const title = edit ? 'Editar Inmueble' : 'Registrar Inmueble';

  const handleSubmit = async () => {
    const valuesWithNoUserId = await form.validateFields();
    const values = { ...valuesWithNoUserId, userId: auth.user ? auth.user.id : null };
    const fd = new FormData();
    setLoading(true);
    fd.append('inmueble', file);
    const modal = Modal.info({
      title: 'Cargando...',
      content: (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Spin indicator={<LoadingOutlined color={thm.primaryColor} style={{ fontSize: 30 }} />} />
          <br />
          <br />
          <Typography.Text>Subiendo documento de soporte...</Typography.Text>
        </div>
      ),
      centered: true,
      maskClosable: false,
      okButtonProps: { disabled: true },
    });
    try {
      if (edit) valuesWithNoUserId.id = property?.id;
      if (file && !file?.url) {
        const response: AxiosResponse = await axios.post(
          `${server}/uploads/inmueble/${valuesWithNoUserId.codCat}?nuevoInmueble=${!edit}`,
          fd,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        modal.update({
          content: (
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <Spin indicator={<LoadingOutlined color={thm.primaryColor} style={{ fontSize: 30 }} />} />
              <br />
              <br />
              <Typography.Text>Subiendo datos del inmueble...</Typography.Text>
            </div>
          ),
        });
        valuesWithNoUserId.dirDoc = response.data.archivos[0];
      }

      handlingMessage({
        action: () =>
          edit
            ? editProperty({ ...valuesWithNoUserId, userId: auth.user ? auth.user.id : null }, auth.token)
            : registerProperty(values, auth.token),
        key: 'submit',
        loadingMessage: 'Realizando operacion...',
        cb: (data) => {
          modal.destroy();
          if (data.status === 200) reset();
          setLoading(false);
        },
      });
    } catch (e) {
      modal.destroy();
      message.error(e?.response?.data?.message || 'Error en el servidor');
    }
  };

  const reset = () => {
    form.resetFields();
    initYear();
    setLoading(false);
    setProperty(null);
  };

  const initYear = () => {
    const yearsAux: number[] = [];
    const today = moment().year();
    let init: number = parseInt(moment(today, 'YYYY').subtract(4, 'years').format('YYYY'));
    while (init <= today) {
      yearsAux.push(init);
      init++;
    }
    setYears(yearsAux);
  };

  useEffect(() => {
    initYear();
    setLoading(true);
    // eslint-disable-next-line
  }, []);
  const searchProperty = async () => {
    setDisabled(true);
    const values = await form.validateFields();
    handlingMessage({
      action: () => getProperty(values.codCatSearch, auth.token),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          const inmueble = { ...data.inmueble };
          inmueble.avaluos = inmueble.avaluos.map((a) => ({ ...a, avaluo: +Number(a.avaluo).toFixed(2) }));
          setProperty(inmueble);
        }
        setDisabled(false);
      },
    });
  };

  useEffect(() => {
    const datos = { ...property, codCat: property?.codigoCatastral };
    if (property?.dirDoc) {
      const urlSplit: string[] = property.dirDoc?.split('/');
      const name = urlSplit[urlSplit.length - 1];
      setFile({ uid: 1, url: property.dirDoc, status: 'done', name });
    }
    form.setFieldsValue({ ...datos });
    // eslint-disable-next-line
  }, [property]);

  const content = (
    <>
      <Divider orientation='left'>
        <Typography.Title level={4}>Datos del inmueble</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={8} xs={24}>
          <Form.Item
            label='Código de identificación del Inmueble'
            name='codCat'
            normalize={Utils.normalize.toUpper}
            rules={[{ required: true, message: 'Debe ingresar el Código de identificación del Inmueble' }]}
          >
            <Input placeholder='Código de identificación del Inmueble' />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            label='Metros de Construcción'
            name='metrosConstruccion'
            rules={[{ required: true, message: 'Debe ingresar la cantidad de metros' }]}
          >
            <InputNumber placeholder='Metros de Construcción' style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            label='Metros de Terreno'
            name='metrosTerreno'
            rules={[{ required: true, message: 'Debe ingresar la cantidad de metros' }]}
          >
            <InputNumber placeholder='Metros de Terreno' style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={8} xs={24}>
          <Form.Item label='Parroquia' name='idParroquia' rules={[{ required: true, message: 'Debe ingresar la parroquia' }]}>
            <Select placeholder='Parroquia'>
              {prsh.parish.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            label='Tipo de Inmueble'
            name='tipoInmueble'
            rules={[{ required: true, message: 'Debe ingresar el tipo de inmueble' }]}
          >
            <Select placeholder='Tipo de Inmueble'>
              {tipoInmueble.map((option, index) => (
                <Select.Option key={index} value={option.value}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Debe ingresar la direccion' }]}>
            <Input maxLength={250} placeholder='Dirección' />
          </Form.Item>
        </Col>
        {/* <Col xl={8} xs={24}>
          <Form.Item
            label='Código de Trámite de CPU'
            name='codigoCpu'
            rules={[{ required: true, message: 'Debe ingresar el código del trámite correspondiente' }]}
          >
            <Input maxLength={25} placeholder='CPU-00000000-0000000-000' />
          </Form.Item>
        </Col> */}
      </Row>
      <Divider orientation='left'>
        <Typography.Title level={4}>Documento soporte del inmueble</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={[16, 24]}>
        <Col xl={8} xs={24}>
          <Dragger
            style={{ width: '100%', padding: 16 }}
            accept='.pdf, image/*'
            name='doc'
            fileList={file ? [file] : []}
            beforeUpload={(file: UploadFile) => {
              setFile(file);
              return false;
            }}
            onRemove={() => setFile(undefined)}
          >
            <p className='ant-upload-drag-icon'>
              <img src={fileImage} alt='pdfIcon' style={{ width: 100 }} />
            </p>
            <p className='ant-upload-text'>Haga clic o arrastre el archivo a esta área para subir el archivo</p>
          </Dragger>
        </Col>
      </Row>
      <Divider orientation='left'>
        <Typography.Title level={4}>Avalúos del inmueble</Typography.Title>
      </Divider>
      <MutiplePropertyValues yearsArray={years} form={form} loading={loading} />
      <Row style={{ marginLeft: 16 }}>
        <Button type='primary' htmlType='submit'>
          {edit ? 'Guardar' : 'Registrar'}
        </Button>
      </Row>
    </>
  );
  const search = (
    <Row style={{ paddingLeft: 16 }} gutter={16}>
      <Col span={12}>
        <Form.Item
          label='Código de identificación del Inmueble'
          name='codCatSearch'
          normalize={Utils.normalize.toUpper}
          rules={[{ required: true, message: 'Debe ingresar el Código de identificación del Inmueble' }]}
        >
          <Input placeholder='Código de identificación del Inmueble' />
        </Form.Item>
      </Col>
      <Col span={12} style={{ alignSelf: 'center', paddingTop: '15px' }}>
        <Button type='primary' disabled={disabled} onClick={() => searchProperty()}>
          Buscar
        </Button>
      </Col>
    </Row>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title={title}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        {edit && search}
        {property && edit && content}
        {!property && !edit && content}
      </Form>
    </Card>
  );
};

interface RegisterPropertyProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  prsh: States.Parish;
  coin: States.Coins;
}

const mapStateToProps = ({ thm, auth, prsh, coin }: State) => ({ thm, auth, prsh, coin });

export default connect(mapStateToProps)(RegisterProperty);
