import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CoinsType, SET_PETRO } from './actionsTypes';
const server = process.env.REACT_APP_SERVER_URL;

export const setPetro = (petro: number): CoinsType => ({ type: SET_PETRO, payload: petro });

export const fetchPetro = (): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.get(`${server}/values/petro`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      dispatch(setPetro(parseFloat(response.data.petro)));
      return response.data;
    } catch(e) {
      throw e;
    }
  };

export const updatePetro = (value:number): ThunkAction<Promise<any>, {}, {}, CoinsType> =>
  async (dispatch: ThunkDispatch<{}, {}, CoinsType>): Promise<any> => {
    try {
      const response: AxiosResponse = await axios.patch(`${server}/values/petro`, { value } , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      console.log(response.data);
      if(response.data.status !== 200) throw new Error(response.data.message);
      dispatch(setPetro(parseFloat(response.data.petro)));
      return response.data;
    } catch(e) {
      throw e;
    }
  };
  

