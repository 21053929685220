import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ChangeProcedureStatusAction, CHANGE_PROCEDURE_STATUS, SET_PROCEDURE, SET_PROCEDURES, ProceduresActionsTypes, ChangeProcedureData, CHANGE_PROCEDURE_DATA, CHANGE_PROCEDURE_CERTIFICATE, SET_FINES, SET_FINE, CHANGE_FINE_STATUS, CHANGE_FINE_CERTIFICATE, SET_SUPPORTS, SET_SUPPORT, CHANGE_SUPPORT_DATA } from './actionsTypes';
import { Procedure, Fine, Support } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

export const setProcedures = (procedures: Procedure[]): ProceduresActionsTypes => ({ type: SET_PROCEDURES, payload: procedures });
export const setSupports = (supports: Support[]): ProceduresActionsTypes => ({ type: SET_SUPPORTS, payload: supports });
export const setProcedure = (procedure: Procedure): ProceduresActionsTypes => ({ type: SET_PROCEDURE, payload: procedure });
export const setSupport = (support: Support): ProceduresActionsTypes => ({ type: SET_SUPPORT, payload: support });

export const changeProcedureCertificate = (id: number, certificate: string): ProceduresActionsTypes => ({ type: CHANGE_PROCEDURE_CERTIFICATE, payload: { id, certificate } });
export const changeProcedureStatus = (id: number, status: string): ChangeProcedureStatusAction => ({ type: CHANGE_PROCEDURE_STATUS, payload: { id, status } });
export const changeProcedureData = (id: number, data: any): ChangeProcedureData => ({ type: CHANGE_PROCEDURE_DATA, payload: { id, data } });
export const changeSupportData = (id: number, data: any): ProceduresActionsTypes => ({ type: CHANGE_SUPPORT_DATA, payload: { id, data } });

export const setFines = (fines: Fine[]): ProceduresActionsTypes => ({ type: SET_FINES, payload: fines });
export const setFine = (fine: Fine): ProceduresActionsTypes => ({ type: SET_FINE, payload: fine });

export const changeFineStatus = (id: number, status: string): ProceduresActionsTypes => ({ type: CHANGE_FINE_STATUS, payload: { id, status } });
export const changeFineCertificate = (id: number, certificate: string): ProceduresActionsTypes => ({ type: CHANGE_FINE_CERTIFICATE, payload: { id, certificate } });

export const updateProcedure = (data: any, token: string): ThunkAction<Promise<void>, {}, {}, ProceduresActionsTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ProceduresActionsTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<UpdateProcedureResponse> = await axios.put(`${server}/procedures/update`, data, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(changeProcedureStatus(response.data.tramite.id, response.data.tramite.estado));
      if(response.data.tramite.certificado) dispatch(changeProcedureCertificate(response.data.tramite.id, response.data.tramite.certificado));
      if(response.data.tramite.tipoTramite === 26) dispatch(changeProcedureData(response.data.tramite.id, response.data.tramite));
    } catch (e) {
      throw e;
    }
  };

export const updateFine = (data: any, token: string): ThunkAction<Promise<void>, {}, {}, ProceduresActionsTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ProceduresActionsTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<UpdateFineResponse> = await axios.put(`${server}/fines/update`, data, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(changeFineStatus(response.data.multa.id, response.data.multa.estado));
      if(response.data.multa.certificado) dispatch(changeFineCertificate(response.data.multa.id, response.data.multa.certificado));
    } catch(e) {
      throw e;
    }
  }

export const createSocialAffair = (data: any, token: string): ThunkAction<Promise<void>, {}, {}, ProceduresActionsTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, ProceduresActionsTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<CreateSocialAffair> = await axios.post(`${server}/affairs/init`, data, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setProcedure(response.data.caso));
    } catch(e) {
      throw e;
    }
  };

export const updateSocialAffair = (data: any, token: string): ThunkAction<Promise<void>, {}, {}, ProceduresActionsTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, ProceduresActionsTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<UpdateSocialAffairResponse> = await axios.put(`${server}/affairs/update`, data, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(changeProcedureStatus(response.data.caso.id, response.data.caso.estado));
      dispatch(changeProcedureData(response.data.caso.id, response.data.caso.datos));
    } catch(e) {
      throw e;
    }
  };

interface UpdateProcedureResponse {
  tramite: Procedure
}

interface UpdateSocialAffairResponse {
  caso: Procedure
}

interface CreateSocialAffair {
  caso: Procedure
}

interface UpdateFineResponse {
  multa: Fine
}