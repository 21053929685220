import { SET_REQUESTS, ADD_REQUEST, PAY_REQUEST, RequestType, SetRequestsAction, ChangeReqStatusAction, CHANGE_REQ_STATUS, REBATE_REQUEST, RebateReqAction } from "./actionsTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios, { AxiosResponse } from "axios";
import { Request } from "sigt";
const server = process.env.REACT_APP_SERVER_URL;

export const setRequests = (requests: Request[]): SetRequestsAction => ({ type: SET_REQUESTS, payload: requests });
export const addRequest = (request: Request) => ({ type: ADD_REQUEST, payload: request });
export const payRequest = (id: number) => ({ type: PAY_REQUEST, payload: id });
export const rebateRequest = (req: Request): RebateReqAction => ({ type: REBATE_REQUEST, payload: req });
export const changeRequestStatus = (status: string, id: number): ChangeReqStatusAction => ({ type: CHANGE_REQ_STATUS, payload: { id, status } });

export const fetchRequests = (token: string): ThunkAction<Promise<void>, {}, {}, RequestType> => 
  async (dispatch: ThunkDispatch<{}, {}, RequestType>): Promise<void> => {
    try {
      const response: AxiosResponse<{ solicitudes: Request[] }> = await axios.get(`${server}/settlements/instances`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setRequests(response.data.solicitudes));
    } catch(e) {
      throw e;
    }
  }