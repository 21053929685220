import axios, { AxiosResponse } from 'axios';
import { AE } from 'sigt';
import { AEType, SET_AES } from './actionsTypes';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
const server = process.env.REACT_APP_SERVER_URL;

export const setActivities = (ae: AE[]): AEType => ({ type: SET_AES, payload: ae });

export const fetchAES = (token: string): ThunkAction<Promise<void>, {}, {}, AEType> =>
  async (dispatch: ThunkDispatch<{}, {}, AEType>): Promise<void> => {
    try {
      const response: AxiosResponse<{ data: AE[] }> = await axios.get(`${server}/activities`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setActivities(response.data.data.sort((a,b) => a.descripcion > b.descripcion ? 1 : -1)));
    } catch(e) {
      throw e;
    }
  }

