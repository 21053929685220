import Icon, { LoadingOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Modal, Row, Select, Tag, Typography } from 'antd';
import axios from 'axios';
import { toUpper } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router';

import '../assets/css/components/ConsultPenalty.css';

const server = process.env.REACT_APP_SERVER_URL;
const carScanningAnim = require('../assets/animations/scannerCar.json');
const userScanningAnim = require('../assets/animations/scannerUser.json');

const ConsultPenalty: React.FC<ConsultPenaltyProps> = () => {
  const [penaltys, setPenaltys] = useState<{fines: boolean, vhlTax?: boolean} | null>(null);
  const [verifying, setVerifying] = useState(false);
  const [animEnd, setAnimEnd] = useState(true);
  const [visible, setVisible] = useState(true);
  const [type, setType] = useState<string>();
  const history = useHistory();
  const [form] = Form.useForm();

  const onSubmit = async () => {
    const values = await form.validateFields();
    setVerifying(true);
    setAnimEnd(false);
    try {
      const response = await axios.get(
        `${server}/validateDoc/${values.tipo === 'PLACA' ? `validarVehiculo?placa=${values.placa}` 
        : `validarUsuario?tipo_documento=${values.tipo_documento}&documento=${values.documento}`}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }});
      setPenaltys({ fines: response.data.fines, vhlTax: response.data.solvent });
    } catch (err) {
    }finally{
      setVerifying(false);
    }
  }

  const docType = (
    <Form.Item noStyle name='tipo_documento'>
      <Select>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const onCancel = () => {
    setVisible(false);
    history.goBack();
    form.resetFields();
  }

  return <Modal centered visible={visible} title='Consulta de Impuesto Vehicular y multas' okButtonProps={{ loading: verifying || !animEnd }} cancelButtonProps={{ disabled: verifying || !animEnd }} onCancel={onCancel} onOk={() => form.submit()}>
    {(verifying || !animEnd) && <Lottie eventListeners={[{
        eventName: 'loopComplete',
        callback: () => setAnimEnd(true),
      }]} options={{ loop: true, autoplay: false, animationData: type === 'PLACA' ? carScanningAnim : userScanningAnim }} width={300} height={300} style={{ marginBottom: 15, marginTop: 15 }} isClickToPauseDisabled />
    }
    {(!verifying && animEnd) && <>
      <Form form={form} onFinish={onSubmit} initialValues={{ tipo_documento: 'V' }} layout='vertical' >
        <Row gutter={[8,8]}>
          <Col flex={1}>
            <Form.Item name='tipo' label='Tipo de Busqueda' rules={[{ required: true, message: 'Seleecione un tipo de busqueda'}]}>
              <Select placeholder='Seleccione el tipo' onChange={(tipo:string) => { setType(tipo); setPenaltys(null) }}>
                <Select.Option value='PLACA'>PLACA</Select.Option>
                <Select.Option value='CEDULA'>CEDULA</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {type === 'PLACA' 
            ? <Col flex={1}>
              <Form.Item name='placa' label='Numero de Placa' rules={[{ required: true, message: 'Debe ingresar un numero de placa' }]} normalize={toUpper}>
                <Input maxLength={7} placeholder='Numero de Placa'/>
              </Form.Item>
            </Col>
            : <Col flex={1}>
              <Form.Item name='documento' label='Documento' rules={[{ required: true, message: 'Debe ingresar un numero de documento' }]}>
                <Input addonBefore={docType} placeholder='Documento'/>
              </Form.Item>
            </Col>}
        </Row>
      </Form>
      {penaltys && <>
        <Divider style={{ marginLeft: -16, borderRadius: 10}} orientation='left'>Multas</Divider>
        <div className={`penaltyCard ${!penaltys.fines ? 'greenCard' : 'redCard' }`}>
          {!penaltys.fines ? 'SOLVENTE' : 'POSEE MULTAS PENDIENTES POR PAGAR'}
        </div>
        {type === 'PLACA' && <>
          <Divider style={{ marginLeft: -16}} orientation='left'>Impuesto Vehicular</Divider>
          <div className={`penaltyCard ${penaltys.vhlTax ? 'greenCard' : 'redCard'}`}>{penaltys.vhlTax ? 'SOLVENTE' : 'NO ESTA AL DIA CON EL IMPUESTO VEHICULAR'}</div>
        </>}
      </>}
    </>}
  </Modal>
}

export default ConsultPenalty;

interface ConsultPenaltyProps {
}