import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fetchBanks = async () => {
  try {
    const response = await axios.get(`${server}/banks`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const validatePayments = async (body: validatePayments) => {
  try {
    const response = await axios.put(`${server}/banks/validatePayments`, body, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

interface validatePayments {
  bank: number;
  data: object[];
}
