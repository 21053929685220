import React, { useState, useEffect } from 'react';
import { Card, message, Col, Row, Divider, Typography, Button, Spin, Form, Input, Tabs, Table, Alert, Upload, Descriptions, Modal, Select} from 'antd';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../utils/hooks';
import moment from 'moment';
import XLSX from 'xlsx';
import { DownloadOutlined, LoadingOutlined, WarningTwoTone, DeleteOutlined, CheckCircleFilled } from '@ant-design/icons';
import excelImage from '../../assets/images/excel.png'
import { UploadFile } from 'antd/lib/upload/interface';
import { ColumnProps } from 'antd/lib/table';
import { addRequest } from '../../redux/actions/requests';
import axios, { AxiosResponse } from 'axios';
import '../../assets/css/components/Retentions.css';
import { useHistory } from 'react-router';
import UserSelect from '../Forms/components/UserSelect';
const server = process.env.REACT_APP_SERVER_URL;

const { Dragger } = Upload;

const toRound2 = (number: number) => Math.round((number + Number.EPSILON) * 100) / 100;

const round8 = (number: number | string): number => { 
  number = (typeof number !== 'number') ? parseFloat(number) : number
  return +Number(number).toFixed(8);
};

const formatCurrency = (number: number | string) => { 
  number = (typeof number !== 'number') ? parseFloat(number) : number
  return new Intl.NumberFormat('de-DE').format(toRound2(number));
};

const Retentions: React.FC<RetentionsProps> = ({ thm, auth, coin, addRequest }) => {
  const [fileList, setFileList] = useState<fileUpload[]>([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [userType, setUserType] = useState('JURIDICO');
  const [activeTab, setActiveTab] = useState('0');
  const [data, setData] = useState<ResponseRetention | null>();
  const [error, setError] = useState<{[key:string] : string[]}>({});
  const [retentions, setRetentions] = useState<Retention[]>([]);
  const [declaring, setDeclaring] = useState(false);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const acceptedDataColumns = ["RIF", "RIM", "CONTRIBUYENTE", "COMPRA/SERVICIO", "FECHA", "NUMERO DE FACTURA", "CODIGO DE ACTIVIDAD", "BASE IMPONIBLE", "PORCENTAJE", "MONTO RETENIDO"];
  const excelTypes = ['application/vnd.ms-excel', 'application/excel', 'application/vnd.ms-excel', 'application/x-excel', 'application/x-msexcel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

  useEffect(()=>{
    form.setFieldsValue({docType: userType === 'JURIDICO' ? 'J' : 'V'});
  },[userType,form])

  const docTypeSelect = (
    <Form.Item noStyle name='docType'>
      <Select>
        {userType !== 'JURIDICO' ? null : 
          <>
            <Select.Option value='J'>J</Select.Option>
            <Select.Option value ='G'>G</Select.Option>
          </>
        }
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
        
      </Select>
    </Form.Item>
  );

  const ExcelDateToMomentJS = (serial) => {
    // let days = Math.floor(serial);
    let hours = Math.floor((serial % 1) * 24);
    let minutes = Math.floor((((serial % 1) * 24) - hours) * 60)
    const full_date = new Date(Date.UTC(0, 0, serial, hours-20, minutes));
    return moment(full_date).format('YYYY/MM/DD');
  }

  const validateFields = (record: RetentionDetails, index: number, key: string) => {
    const docTypes = ['J', 'V', 'P', 'G', 'E'];
    const serviceType = ['C', 'S', 'NC'];
    const errors : string[] = [];

    if (!docTypes.includes(record.rif.substring(0,1)) || Number.isNaN(parseInt(record.rif.substring(1)))) { 
      errors.push(`RIF invalido en registro encontrado en la fila ${index+1} del excel cargado, el formato debe ser el siguiente: J123456789`); 
    }

    if ((record.rim.length !== 10 && record.rim !== '0')){ 
      errors.push(`RIM invalido en registro encontrado en la fila ${index+1} del excel cargado, el formato debe ser el siguiente: 1234567890`)
    } 

    if (!serviceType.includes(record.tipoServicio?.toUpperCase())){ 
      errors.push(`Compra/Servicio en registro de la fila ${index+1} debe ser denotado por C, S o NC.`);
    }

    if (!moment(record.fecha).isValid()){
      errors.push(`Fecha en registro de la fila ${index+1} no es valido o no esta en su debido formato`);
    }

    if (Number.isNaN(record.baseImponible) || (record.tipoServicio?.toUpperCase() !== 'NC' && record.baseImponible <= 0)){
      errors.push(`Base inmponible en registro de la fila ${index+1} no es numero positivo o no esta en su debido formato. NOTA: si desea ingresar una nota de credito debe colocar "NC" en el campo COMPRA/SERVICIO`);
    }

    if (Number.isNaN(record.codActividad)){
      errors.push(`Codigo de actividad en registro de la fila ${index+1} no es numero positivo o no esta en su debido formato`);
    }

    if (Number.isNaN(record.montoRetenido) || (record.tipoServicio?.toUpperCase() !== 'NC' && record.montoRetenido <= 0)){
      errors.push(`Monto Retenido en registro de la fila ${index+1} no es numero positivo o no esta en su debido formato \n NOTA: si desea ingresar una nota de credito debe colocar "NC" en el campo COMPRA/SERVICIO`);
    }

    if (toRound2(record.baseImponible * 0.01) !== toRound2(record.montoRetenido)){
      errors.push(`Monto Retenido en registro de la fila ${index+1} no corresponde al 1% de la base imponible o no es un numero positivo`);
    }
   
    return errors;
  } 

  const readExcel = (file, {month,year}) => {
    const reader = new FileReader();
    let buffer: any;
    let json: RetentionDetails[] = [];
    setError((pErr)=>({...pErr, [month+year]: []}))
    reader.onload = (event: any) => {
      buffer = event.target.result;
      try {
        //raw is used to keep all data as string
        let data = XLSX.read(buffer, { type: 'buffer', raw: true });
        let retentionsArr: any[] = XLSX.utils.sheet_to_json(data.Sheets[data.SheetNames[0]], { header: 1 });
        let initIndex = retentionsArr.findIndex(e => e.length > 2);

        acceptedDataColumns.forEach((e,i) => {
          const colIndex = retentionsArr[initIndex]?.findIndex((c:string)=>e === c?.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
          if(colIndex === -1){
            setError((pErr) => ({...pErr, [month+year]: [...pErr[month+year], `El campo ${e} debe llamarse al igual que se refleja en el formato base o esto se debe a que no existe el campo en el archivo, por favor asegurese de que exista en el formato.`]}));
            throw (new Error('Error en encabezados'));
          }else if (colIndex !== i){
            setError((pErr) => ({...pErr, [month+year]: [...pErr[month+year], `Asegurese que el campo ${retentionsArr[initIndex][colIndex]} se encuentre en la celda ${String.fromCharCode(65+i)}`]}));
            throw (new Error('Error en encabezados'));
          }
        })
        
        initIndex++;
        let monto = 0;
        const errors: string[] = [];
        
        while(initIndex < retentionsArr.length && retentionsArr[initIndex].length > 0){
          monto+= toRound2(retentionsArr[initIndex][9]);
          const record = {
            index: initIndex + 1,
            rif: retentionsArr[initIndex][0].toString().trim(),
            rim: retentionsArr[initIndex][1].toString().trim(),
            razonSocial: retentionsArr[initIndex][2],
            tipoServicio: retentionsArr[initIndex][3],
            fecha: Number.isInteger(retentionsArr[initIndex][4]) ? ExcelDateToMomentJS(retentionsArr[initIndex][4]) : moment(retentionsArr[initIndex][4]).format('YYYY/MM/DD'),
            numeroFactura: retentionsArr[initIndex][5],
            codActividad: retentionsArr[initIndex][6],
            baseImponible: toRound2(retentionsArr[initIndex][7]),
            porcentaje: retentionsArr[initIndex][8],
            montoRetenido: toRound2(retentionsArr[initIndex][9])
          };
          const err = validateFields(record,initIndex+1,month+year);
          errors.push(...err);
          json.push({...record, error: err.length > 0});
          initIndex++;
        }

        setError((pErr)=>({...pErr, [month+year]: errors}));
        const _retentions = [...retentions];
        const rIndex = _retentions.findIndex((f)=> f.fechaCancelada.month === month && f.fechaCancelada.year === year);
        if(rIndex !== -1){
          _retentions[rIndex] = { fechaCancelada: { month, year }, items: json, monto: toRound2(monto)};
        }else{
          _retentions.push({fechaCancelada: { month, year }, items: json, monto: toRound2(monto)})
        }
        if( monto < 0 ) setError((pErr) => ({...pErr, [month+year]: ['LA SUMA DEL MONTO TOTAL RETENIDO NO PUEDE SER DE VALOR NEGATIVO.']}));
        setRetentions(_retentions);
      } catch (err) {
        message.error(err.message);
      }
    }
    reader.readAsArrayBuffer(file);
  };

  const onSearch = async () => {
    const {rim, document, docType} = await form.validateFields();
    try {
      setLoading(true);
      const response : AxiosResponse<{retenciones: ResponseRetention, message: string}> = await axios.get(`${server}/retentions?doc=${document || auth.user?.contribuyente?.documento}&ref=${rim}&pref=${docType || auth.user?.contribuyente?.tipoDocumento}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setData(response.data.retenciones);
      response.data.retenciones.RD.forEach((rd)=> setError((p)=>({...p, [rd.month+rd.year]: [] })));
      message.success(response.data.message);
    } catch(e) {
      message.error(e.response.data?.message || 'Error en el servidor');
    } finally {
      setLoading(false);
    }
  }

  const onDeclare = async () => {
    let total = 0;
    const _retentions = retentions.map((c) => {
      total += c.monto ? c.monto : 0
      return {...c, monto: round8((c.monto || 0) / coin.petro)};
    });
    if (total < 0) return message.error('El monto total por retenciones no puede ser de valor negativo.')

    const declaration : RetentionDeclaration = {
      documento: data?.documento,
      rim: data?.rim,
      tipoDocumento: data?.tipoDocumento,
      retenciones: _retentions,
      totalPago: round8(total / coin.petro),
      contribuyente: data?.contribuyente,
      usuario: form.getFieldValue('usuario')
    }

    try {
      setDeclaring(true);
      const response = await axios.post(`${server}/retentions/init`,declaration,{ headers: { Authorization: `Bearer ${auth.token}` } });
      message.success(response?.data?.message);
      addRequest(response?.data?.solicitud);
      if(auth.user?.tipoUsuario !== 4){
        setData(null);
        setError({});
        setRetentions([]);
        setFileList([]);
      }else{
        history.push(`/dashboard/impuestos/pagar/${response?.data?.solicitud?.id}`);
      }
    } catch(e) {
      message.error(e?.response?.data?.message || e?.response?.data?.error || 'Error en el servidor');
      if (e.response?.data?.rif){
        const eIndex = e.response?.data?.index || -1;
        const month = e.response?.data?.fechaCancelada?.month;
        const year = e.response?.data?.fechaCancelada?.year;
        const _retentions = [...retentions];
        const rIndex = _retentions.findIndex((r) => r.fechaCancelada.month === month && r.fechaCancelada.year === year);
        const _items = [..._retentions[rIndex].items];  
        _items[eIndex].error = true;
        _retentions[rIndex] = Object.assign({}, _retentions[rIndex]);
        setRetentions(_retentions);
        setError((pErr)=>({...pErr, [month+year]: [...pErr[month+year], `En la linea ${_items[eIndex].index} del archivo excel: ${e.response.data.message}`]}));
      }
    } finally {
      setDeclaring(false);
    }
  }

  const getTotals = () => {
    let total: number = 0;
    return (<><Divider style={{marginLeft: -16}} orientation='left'><Typography.Title level={4}>Total Declaración</Typography.Title></Divider>
      <Row gutter={[16,16]}><Col xs={24} xl={12}>
        <Descriptions column={1} layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
          {retentions.map((e,i)=>{
            total += e.monto ? e.monto : 0 ;
            return ( 
              <Descriptions.Item key={i} label={`${e.fechaCancelada?.month.toUpperCase()} ${e.fechaCancelada?.year}`}>{typeof e.monto === 'number'  ? `Bs. ${formatCurrency(e.monto)}` : 'N/A'}</Descriptions.Item>
            );
          })}
          <Descriptions.Item key='total' label='TOTAL'>
            <Typography.Text strong>Bs. {formatCurrency(total)}</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </Col></Row>
    </>);
  }

  const getPanelSubtitle = (month:string, year: number, index: number) => {
    const isDeclared = !isNaN(retentions[index]?.monto as any) && error[month+year]?.length === 0;
    return (
      <span>
        {month.toUpperCase()} {year}
        <CheckCircleFilled style={{ color: isDeclared ? 'green' : 'gray' , marginLeft: 5 }} />
      </span>
    )
  };
 
  const getFileList = (index) => {
    if (index !== -1){
      return [fileList[index].file]
    }else return [];
  }

  const onDeleteFile = (month: string, year: number) => {
    setFileList(fileList.filter(f => f.date !== month+year));
    setError({...error, [month+year] : []});
    setRetentions(retentions.filter((e) => e.fechaCancelada.month !== month || e.fechaCancelada.year !== year));
  }

  const columnsTable: ColumnProps<RetentionDetails>[] = [
    { title: 'RIF', dataIndex: 'rif' },
    { title: 'RIM', dataIndex: 'rim' },
    { title: 'Contribuyente', dataIndex: 'razonSocial'},
    { title: 'Compra / Servicio', dataIndex: 'tipoServicio'},
    { title: 'Fecha', dataIndex: 'fecha', render: (value)=> moment(value).format('DD/MM/YYYY')},
    { title: 'Nro. Factura', dataIndex: 'numeroFactura'},
    { title: 'Codigo de Actividad', dataIndex: 'codActividad'},
    { title: 'Base inmponible', dataIndex: 'baseImponible', render:(value) => formatCurrency(value)},
    { title: '%', dataIndex: 'porcentaje', width: 50, render: () => 1},
    { title: 'Monto Retenido', dataIndex: 'montoRetenido', render:(value) => formatCurrency(value), fixed: 'right'}
  ]

  const allIsDeclared = () => {
    return !data?.RD.some((e) => isNaN(retentions.find((r)=>r.fechaCancelada.month === e.month && r.fechaCancelada.year === e.year)?.monto as any)) && !Object.keys(error).some((key) => error[key].length > 0);
  }

  return <Card style={{ height: '100%' }} title='Declarar Retenciones' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
  headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
    {((auth?.user?.tipoUsuario === 4 && auth.user?.contribuyente) || data) && 
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text strong>Razón Social: {auth.user?.contribuyente?.razonSocial || data?.razonSocial }</Typography.Text>
        <Typography.Text strong>
          Documento: {auth.user?.contribuyente?.tipoDocumento || data?.tipoDocumento}-{auth.user?.contribuyente?.documento || data?.documento}
          {data?.rim && 
            <React.Fragment>
              <br />
              R.I.M. - A.R: {data.rim}
            </React.Fragment>}
        </Typography.Text>
      </div>}

    {!data ? <Form form={form} layout='vertical'  style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={onSearch}>
       <Row align='middle' gutter={16}>
        {loading ?
          <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spin tip='Consultando...' indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />} />
          </Col> : <>
              <Col span={24} style={{ marginBottom: 15, marginTop: 10 }}>
                <Typography.Text>
                  Para la declaración de retenciones debe ingresar el registro municipal de agente de retención correspondiente.
                </Typography.Text>
              </Col>
              {auth.user?.tipoUsuario !== 4 && <> <Col xl={7} xs={24}>
                  <Form.Item label='Tipo de Contribuyente' name='userType' rules={[{ required: true, message: 'Debe ingresar el tipo de contribuyente' }]}>
                    <Select style={{marginTop: 0}} placeholder='Tipo de Contribuyente' onChange={(value:string) => setUserType(value)}>
                      <Select.Option value='NATURAL'>Natural</Select.Option>
                      <Select.Option value='JURIDICO'>Jurídico</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={7}>
                  <Form.Item label='Documento de Identidad' name='document' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}>
                    <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
                  </Form.Item>
                </Col>
              </>}
              <Col xs={24} md={12} xl={8}>
                <Form.Item label='R.I.M. Agente de Retención' name='rim' rules={[{required: true, message:'Ingrese un R.I.M.'}]}>
                  <Input placeholder='AR0000000'/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button type='primary' htmlType='submit'>Consultar</Button>
              </Col>
            </>
        }
        </Row>
      </Form>
    : <>
        <Typography.Text style={{marginTop: 16}}><span><WarningTwoTone style={{marginRight: 8}}/>Tenga en cuenta que debe subir un archivo tipo EXCEL (.xls, .xlsx) correspondiente a cada mes en base a la plantilla 
          o formato establecida por el departamento de cobranza de SAT.</span></Typography.Text>
        <br/>
        <Typography.Text style={{marginTop: 16}}><span><WarningTwoTone style={{marginRight: 8}}/>Si desea ingresar una nota de credito debe denotarlo con las siglas <strong>NC</strong></span></Typography.Text>
        <br/>
        <Typography.Text strong>De no poseer la plantilla o formato: <Button style={{margin: '8px 0'}} icon={<DownloadOutlined/>} type='link' download href='https://sut-maracaibo.s3.us-east-2.amazonaws.com/Recursos/Formato_base_de_retenciones.xlsx'>Descargar aquí</Button></Typography.Text>
        {data.usuarios?.length > 0 && auth.user?.tipoUsuario !== 4 &&
          <>
            <Form form={form} layout='vertical'>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Usuario de S.U.T. 
                </Typography.Title>
              </Divider>
              <UserSelect users={data.usuarios} form={form} />
            </Form>
          </>}
        <Tabs animated style={{marginTop: 16}} defaultActiveKey='0' activeKey={activeTab} onChange={tab =>  setActiveTab(tab)} type="card">{data?.RD?.map((e,i)=>{
          const fIndex = fileList.findIndex((f)=> f.date === (e.month+e.year));
          const rIndex = retentions.findIndex((r)=> r.fechaCancelada.month === e.month && r.fechaCancelada.year === e.year);
          return (<Tabs.TabPane key={`${i}`} tab={getPanelSubtitle(e.month, e.year, rIndex)}>
            <Divider style={{marginLeft: -16}} orientation='left'><Typography.Title level={4}>Archivo</Typography.Title></Divider>
            <Row style={{flexDirection: 'column'}} align='top' gutter={[16, 8]}>
              <Col>
                <Dragger  style={{ width: '100%', padding: 16 }} showUploadList={false} accept='.xls,.xlsx' fileList={getFileList(fIndex)}
                  beforeUpload={(file: UploadFile) => {
                    if (excelTypes.includes(file.type)){
                      const _fileList = [...fileList];
                      if(fIndex !== -1){
                        _fileList[fIndex] = {date: e.month+e.year, file};
                      }else{
                        _fileList.push({date: e.month+e.year, file})
                      }
                      setFileList(_fileList);
                      readExcel(file, {month: e.month, year: e.year});
                    }else{
                      message.error('Solo son aceptados los archivos tipo XLS o XLSX');
                    }
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <img src={excelImage} alt="excelIcon" style={{ width: 100 }} /> 
                  </p>
                  <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para subir el archivo</p>
                </Dragger>
              </Col>
              <Col>{fIndex !== -1 && <span >{fileList[fIndex].file?.name}
                <Button type='link' onClick={()=>onDeleteFile(e.month, e.year)} style={{border: 'none'}} icon={<DeleteOutlined/>}/></span>}
              </Col>
            </Row>
            <Divider style={{marginLeft: -16}} orientation='left'><Typography.Title level={4}>Data</Typography.Title></Divider>
            {error[e.month+e.year]?.length > 0 && error[e.month+e.year].map((err,ei)=><Alert key={ei} style={{margin: '8px 0'}} message={err} banner type='error'/>)}
            <Table rowKey={({index})=>index} rowClassName={({error})=> error ? 'errorRow' : '' } pagination={{ pageSize: 100 }}bordered columns={columnsTable} scroll={{ x: true }} dataSource={retentions[rIndex]?.items}/>
            <Row justify='end'><Col xs={24} xl={12}>
              <Descriptions column={1} bordered style={{ border: '1px solid #f0f0f0' }}>
                <Descriptions.Item label='Total Monto Retenido'><Typography.Text strong>Bs. {formatCurrency(retentions[rIndex]?.monto || 0)}</Typography.Text></Descriptions.Item>
              </Descriptions>
            </Col></Row>
          </Tabs.TabPane>)})
        }
      </Tabs>
      {getTotals()}
      {!allIsDeclared() ? <Alert banner type='info' message='Debe declarar todos los meses correspondientes para poder finalizar la declaración' /> : <Button type='primary' onClick={()=>onDeclare()}>Declarar</Button>}
    </>}
    <Modal title='Creando declaración de  retenciones' visible={declaring} cancelButtonProps={{ hidden: true }} bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      okButtonProps={{ hidden: true }} closable={false}>
      <Spin tip='Creando declaración...' indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />} />
    </Modal>
  </Card>
}

const mapStateToProps = (({ thm, auth, coin }: State ) => ({ thm, auth, coin }));

export default connect(mapStateToProps, { addRequest })(Retentions);

interface RetentionsProps {
  thm: States.ThemeColors
  auth: States.Auth
  coin: States.Coins
  addRequest: (request: any) => any
}

interface fileUpload {
  date: string;
  file: UploadFile;
}

interface Retention {
  fechaCancelada: { month: string,  year: number }
  items: RetentionDetails[], 
  monto?: number
}

interface RetentionDetails {
  index: number;
  rif: string;
  rim: string;
  razonSocial: string;
  tipoServicio: 'C' | 'S' | 'c' | 's' | 'NC' | 'nc';
  fecha: string;
  numeroFactura: string;
  codActividad: number;
  baseImponible: number;
  porcentaje: number;
  montoRetenido: number;
  error?: boolean;
}

interface RetentionDeclaration {
  usuario?: number
  documento?: string
  rim?: string
  tipoDocumento?: string
  retenciones: Retention[]
  totalPago: number
  contribuyente?: number
}

interface ResponseRetention { 
  RD: { month: string, year: number, exonerado: boolean }[];
  contribuyente: number;
  documento: string;
  razonSocial: string;
  rim: string;
  siglas: string;
  tipoDocumento: string;
  usuarios:{ id: number, correo: string }[]
}
