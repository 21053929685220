import React from 'react';
import { Card } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
import GetTaxpayerInfo from './components/GetTaxpayerInfo';

const ConsultSolventStatus: React.FC<ConsultTaxpayerProps> = ({ thm }) => {
  const { width } = useWindowDimensions();

  return (
    <Card style={{ height: '100%' }} title='Consulta de Estatus Solvente' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}}>
      <GetTaxpayerInfo />
    </Card>
  );
};

const mapStateToProps = (state: State) =>  ({ thm: state.thm });

export default connect(mapStateToProps)(ConsultSolventStatus);

interface ConsultTaxpayerProps {
  thm: States.ThemeColors
}