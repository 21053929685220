import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk'; 
import { BankTypes, SET_BANKS } from './actionsTypes';
import { Banco } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

export const setBanks = (banks: Banco[]): BankTypes => ({ type: SET_BANKS, payload: banks });

export const fetchBanks = (token: string): ThunkAction<Promise<void>, {}, {}, BankTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, BankTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<FetchBanksResponse> = await axios.get(`${server}/banks`, { headers: { Authorization: `Bearer ${token}` } });
      dispatch(setBanks(response.data.banks));
    } catch(e) {
      throw e;
    }
  }

interface FetchBanksResponse {
  banks: Banco[]
}