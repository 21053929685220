import io from 'socket.io-client';
// import { fromEvent } from 'rxjs';

export class SocketService {
  private server: string = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';
  private socket: SocketIOClient.Socket = {} as SocketIOClient.Socket;

  public init (token: string): SocketService {
    this.socket = io(this.server, { query: { token } });
    return this;
  }
  public on(event: string, fn: Function) {
    this.socket.on(event, fn);
    }

  public off(event: string, fn?: Function) {
    this.socket.off(event, fn);
    }

  public disconnect (): void {
    if(this.socket && this.socket.disconnect) this.socket.disconnect();
  }
}