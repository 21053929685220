import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { States, State } from 'sigt';
import {
  Card,
  Form,
  Select,
  Tag,
  Button,
  InputNumber,
  message,
  Row,
  Col,
  Input,
  Alert,
  Divider,
  Typography,
  Descriptions,
} from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import Table, { ColumnsType } from 'antd/lib/table';
import { getStatements, declareSettlements } from '../../services/statements';
import { cloneDeep } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import Petro from '../Icons/Petro';

const SupplementaryStatement: React.FC<SupplementaryStatementProps> = ({ thm, auth }) => {
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [loading, setLoading] = useState(false);
  const [declaring, setDeclaring] = useState(false);
  const [liquidaciones, setLiquidaciones] = useState<any[]>([]);
  const [selectedLiq, setSelectedLiq] = useState<any>(null);
  const [data, setData] = useState<any>({});

  const petro = useSelector((state: State) => state.coin.petro);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      dataIndex: ['contribuyente', 'documento'],
      render: (doc, r) => (
        <span>
          {r.contribuyente.tipoDocumento}-{doc}
        </span>
      ),
    },
    {
      title: 'R.I.M.',
      dataIndex: 'referencia',
      render: (rim) => <span>{rim ? rim : 'N/A'}</span>,
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      render: (tipo) => <span>Actividades Económicas</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => <span>{fecha && fecha.month ? `${firstLetterMayus(fecha.month)} ${fecha.year}` : 'Sin Fecha'}</span>,
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'montoPetro',
      render: (monto, record) => <span>Bs. {formatCurrency(Math.round(+((+monto || 0) * petro || record.monto)))}</span>,
    },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      render: (monto, record) => (
        <span>
          {monto || Number(+((record.monto || 0) / petro).toFixed(8))} <Petro />
        </span>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'monto',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render: (_, record) => (
        <Button type='primary' onClick={() => setSelectedLiq(record)}>
          Declarar
        </Button>
      ),
    },
  ];

  const AEcolumns: ColumnsType<any> = [
    {
      title: 'Aforo',
      dataIndex: 'nombreActividad',
      width: '30%',
    },
    {
      title: 'Alicuota',
      dataIndex: 'alicuota',
      key: 'alicuota',
      render: (record) => <span>{parseFloat((record * 100).toPrecision(15)).toFixed(2)}%</span>,
    },
    {
      title: 'Mínimo Tributable',
      dataIndex: 'minimoTributable',
      render: (mt, record) => (!record.exonerado ? <span>Bs. {formatCurrency(+mt)}</span> : <b>EXONERADO</b>),
    },
    {
      title: 'Monto a Declarar (Bs.)',
      dataIndex: 'date',
      render: (date, record) => {
        return (
          <Form.Item name={record.id} style={{ margin: 0, width: '100%' }}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
              min={0}
              step={1}
              style={{ width: '100%' }}
              placeholder='Monto'
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Impuestos',
      dataIndex: 'date',
      render: (date, record) => {
        const exists = data[record.id];
        return (
          <span>
            {exists
              ? `Bs. ${formatCurrency(
                  parseFloat((data[record.id] * record.alicuota).toFixed(2)) <= record.minimoTributable && isMinTributable(record)
                    ? record.minimoTributable
                    : parseFloat(
                        (
                          +(data[record.id] * record.alicuota).toFixed(2) +
                          (selectedLiq?.estado !== 'finalizado' ? record.montoCobrado : 0)
                        ).toFixed(2)
                      )
                )}`
              : exists === 0
              ? `Bs. ${
                  isMinTributable(record)
                    ? formatCurrency(record.minimoTributable)
                    : formatCurrency(+(selectedLiq?.estado !== 'finalizado' ? record.montoCobrado : 0))
                }`
              : 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'MMV',
      dataIndex: 'date',
      render: (date, record) => {
        const exists = data[record.id];
        return (
          <span>
            {exists
              ? `${
                  Number((data[record.id] * record.alicuota).toFixed(2)) <= record.minimoTributable && isMinTributable(record)
                    ? Number(+(record.minimoTributable / petro).toFixed(8))
                    : Number(
                        +(
                          +(data[record.id] * record.alicuota).toFixed(2) +
                          (selectedLiq?.estado !== 'finalizado' ? record.montoCobrado : 0)
                        ).toFixed(8) / petro
                      ).toFixed(8)
                }`
              : exists === 0
              ? `${
                  isMinTributable(record)
                    ? Number(+(record.minimoTributable / petro).toFixed(8))
                    : Number(+(selectedLiq?.estado !== 'finalizado' ? +(record.montoCobrado / petro).toFixed(8) : 0))
                }`
              : 'N/A'}{' '}
            <Petro />
          </span>
        );
      },
    },
  ];

  const readOnlyAE = cloneDeep(AEcolumns);
  readOnlyAE[3] = {
    title: 'Monto Declarado',
    dataIndex: 'montoDeclarado',
    render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
  };
  readOnlyAE[4] = {
    title: 'Impuestos',
    dataIndex: 'montoCobrado',
    render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
  };
  readOnlyAE[5] = {
    title: 'MMV',
    dataIndex: 'montoCobrado',
    render: (monto) => (
      <span>
        {+(monto / petro).toFixed(8)} <Petro />
      </span>
    ),
  };

  const search = async () => {
    const values = await form.validateFields();
    const params = {
      referencia: values.referencia,
      documento: values.documento || auth.user?.contribuyente?.documento,
      tipoDocumento: auth.user?.tipoUsuario === 4 ? auth.user?.contribuyente?.tipoDocumento : tipoDocumento,
    };
    setLoading(true);
    try {
      const liqs = await getStatements('complementaria', params as any, auth.token);
      setLiquidaciones(
        liqs.map((l) => ({
          ...l,
          ...params,
          contribuyente: params,
        }))
      );
      message.success('Liquidaciones obtenidas de manera exitosa');
    } catch (e) {
      message.error(
        e.response?.data?.message || 'No se encontraron liquidaciones a las cuales se les pueda hacer una declaración sustitutiva'
      );
    } finally {
      setLoading(false);
    }
  };

  const declare = async () => {
    if (selectedLiq && isNaN(getAESubtotal() as any)) return message.error('Debe declarar todos los aforos para poder continuar');
    setDeclaring(true);
    try {
      const desglose = selectedLiq.datos.desglose.map((d) => ({
        montoDeclarado: selectedLiq?.estado === 'finalizado' ? data[d.id] : data[d.id] + d.montoDeclarado,
        montoCobrado: isMinTributable(d)
          ? d.minimoTributable
          : selectedLiq?.estado === 'finalizado'
          ? +(data[d.id] * d.alicuota).toFixed(2)
          : +((data[d.id] + d.montoDeclarado) * d.alicuota).toFixed(2),
        aforo: d.id,
      }));
      const desgloseFull = selectedLiq.datos.desglose.map((d) => ({
        ...d,
        montoDeclarado: selectedLiq?.estado === 'finalizado' ? data[d.id] : data[d.id] + d.montoDeclarado,
        montoCobrado: isMinTributable(d)
          ? d.minimoTributable
          : selectedLiq?.estado === 'finalizado'
          ? +(data[d.id] * d.alicuota).toFixed(2)
          : +((data[d.id] + d.montoDeclarado) * d.alicuota).toFixed(2),
        aforo: d.id,
      }));
      const liquidacion = {
        id: selectedLiq.id,
        desglose,
        monto:
          selectedLiq?.estado !== 'finalizado'
            ? +(parseFloat(getAESubtotal(true) || '0') / petro).toFixed(8)
            : +(desglose.reduce((p, v) => p + v.montoCobrado, 0) / petro).toFixed(8),
      };
      const liq = await declareSettlements('complementaria', [liquidacion], auth.token);
      message.success('Declaración complementaria creada exitosamente');
      if (selectedLiq?.estado !== 'finalizado') {
        const lIndex = liquidaciones.findIndex((l) => l.id === liq.id);
        const _liqs = cloneDeep(liquidaciones);
        _liqs[lIndex] = {
          ..._liqs[lIndex],
          datos: {
            ..._liqs[lIndex].datos,
            desglose: desgloseFull,
          },
          monto: Math.round(liq.monto * petro),
          montoPetro: liq.monto,
        };
        setLiquidaciones(_liqs);
      } else {
        setLiquidaciones([]);
      }
      setSelectedLiq(null);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al crear declaración sustitutiva');
    } finally {
      setDeclaring(false);
    }
  };

  const isMinTributable = (record) => {
    if (record.exonerado) return false;
    if (typeof data[record.id] !== 'undefined' && typeof data[record.id] === 'number') {
      const lowerFee = selectedLiq.datos.desglose
        .sort((a, b) => {
          if (a.alicuota > b.alicuota) return 1;
          if (b.alicuota > a.alicuota) return -1;
          return 0;
        })
        .find(
          (ae) =>
            (data[record.id] + selectedLiq?.datos?.desglose?.find((e) => e.id === record.id)?.montoDeclarado) * ae.alicuota <=
            ae.minimoTributable
        );
      return lowerFee?.id === record.id;
    }
    return false;
  };

  const getAESubtotal = (ignore: boolean = false) => {
    const ids: number[] = selectedLiq.datos.desglose.map((d) => d.id);
    if (ids.every((id) => typeof data[id] !== 'undefined' && typeof data[id] === 'number')) {
      return selectedLiq.datos.desglose
        .map((l) =>
          isMinTributable(l)
            ? l.minimoTributable
            : +(
                (data[l.id] + (ignore ? l.montoDeclarado : selectedLiq?.estado !== 'finalizado' ? l.montoDeclarado : 0)) *
                l.alicuota
              ).toFixed(2)
        )
        .reduce((p, c) => p + c, 0);
    }
    return 'N/A';
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Declaración Complementaria'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form
        layout='vertical'
        form={form}
        onValuesChange={(c, v) => {
          form.setFieldsValue(v);
          setData(v);
        }}
      >
        {!selectedLiq ? (
          <>
            <Row gutter={24}>
              {auth.user?.tipoUsuario !== 4 && (
                <Col xs={24} xl={7}>
                  <Form.Item
                    label='Documento de Identidad'
                    name='documento'
                    rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
                  >
                    <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} xl={7}>
                <Form.Item
                  label='R.I.M.'
                  name='referencia'
                  rules={[{ required: true, message: 'Debe ingresar la referencia municipal' }]}
                >
                  <Input placeholder='R.I.M.' />
                </Form.Item>
              </Col>
              <Col xl={3} xs={12}>
                <Button
                  onClick={() => search()}
                  style={{ marginTop: width < 1200 ? 0 : 38, width: '100%' }}
                  loading={loading}
                  icon={<SearchOutlined />}
                  type='primary'
                >
                  Buscar
                </Button>
              </Col>
              <Col span={24}>
                <Table
                  style={{ marginTop: width < 1200 ? 38 : 0 }}
                  columns={columns}
                  dataSource={liquidaciones}
                  rowKey='id'
                  bordered
                  loading={loading}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row style={{ marginBottom: 5 }}>
              <Col span={24}>
                <Alert
                  style={{ fontWeight: 'bold' }}
                  type='info'
                  message='Debe tener en cuenta que la declaración complementaria será sumada a la declaración previa y por consiguiente el monto a pagar se verá afectado'
                />
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -20 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Declaración Previa
              </Typography.Title>
            </Divider>
            <Row>
              <Col span={24}>
                <Table
                  columns={readOnlyAE}
                  dataSource={selectedLiq.datos.desglose}
                  bordered
                  pagination={false}
                  rowKey='id'
                  style={{ marginTop: -5 }}
                />
                <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                  <Descriptions.Item label='Sub-Total'>
                    Bs. {formatCurrency(+selectedLiq?.datos?.desglose?.reduce((p, v) => p + v.montoCobrado, 0).toFixed(2))}
                  </Descriptions.Item>
                  <Descriptions.Item label='Costo por Solvencia'>
                    {selectedLiq?.datos?.desglose?.[0]
                      ? `Bs. ${formatCurrency(selectedLiq?.datos?.desglose[0].costoSolvencia || 0)}`
                      : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Total'>
                    Bs.{' '}
                    {formatCurrency(
                      +(
                        selectedLiq.datos?.desglose?.reduce((p, v) => p + v.montoCobrado, 0) +
                        selectedLiq?.datos?.desglose[0].costoSolvencia
                      ).toFixed(2)
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -20, marginTop: 20 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Declaración Complementaria
              </Typography.Title>
            </Divider>
            <Alert
              style={{ fontWeight: 'bold', marginTop: -20, marginBottom: 15 }}
              type='warning'
              message='Para el cálculo de los impuestos a pagar se suma el monto declarado en la declaración previa y el monto a declarar de la declaración complementaria'
            />
            <Row>
              <Col span={24}>
                <Table columns={AEcolumns} dataSource={selectedLiq.datos.desglose} bordered pagination={false} rowKey='id' />
              </Col>
              <Col span={24}>
                <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                  <Descriptions.Item label='Sub-Total'>
                    {isNaN(getAESubtotal() as any) ? 'N/A' : `Bs. ${formatCurrency(parseFloat(getAESubtotal() || '0'))}`}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='Costo por Solvencia'
                    style={{ fontWeight: selectedLiq?.estado === 'finalizado' ? 'bolder' : 'normal' }}
                  >
                    {selectedLiq?.datos?.desglose?.[0]
                      ? selectedLiq?.estado !== 'finalizado'
                        ? `Bs. ${formatCurrency(selectedLiq?.datos?.desglose[0].costoSolvencia || 0)}`
                        : 'PAGADA'
                      : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Total'>
                    {isNaN(getAESubtotal() as any)
                      ? 'N/A'
                      : `Bs. ${formatCurrency(
                          parseFloat(getAESubtotal() || '0') +
                            (selectedLiq?.estado === 'finalizado' ? 0 : selectedLiq?.datos?.desglose[0].costoSolvencia) || 0
                        )}`}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            {/* <Divider orientation='left' style={{ marginLeft: -20, marginTop: 20 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Total a Pagar
            </Typography.Title>
          </Divider>
          <Row>
            <Col span={8}>
              <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                <Descriptions.Item label='Total'>{isNaN(getAESubtotal() as any) ? 'N/A' : `Bs. ${formatCurrency(parseFloat(getAESubtotal() || '0') + +(selectedLiq.datos?.desglose?.reduce((p, v) => p + v.montoCobrado, 0) + selectedLiq?.datos?.desglose[0].costoSolvencia).toFixed(2))}`}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row> */}
            <Row gutter={12} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => setSelectedLiq(null)}>Atrás</Button>
              </Col>
              <Row gutter={12}>
                <Col>
                  <Button type='primary' loading={declaring} onClick={() => declare()}>
                    Declarar
                  </Button>
                </Col>
              </Row>
            </Row>
          </>
        )}
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(SupplementaryStatement);

interface SupplementaryStatementProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}
