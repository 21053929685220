import React, { useState } from 'react';
import { States, State } from 'sigt';
import { Card, Form, Row, Divider, Typography, Col, Input, Button, message, Select } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { connect } from 'react-redux';
import { useForm } from 'antd/lib/form/util';
import { Utils } from '../../../utils/validators';
import TaxpayerSocietyType from '../../Forms/components/TaxpayerSocietyType';
import axios from 'axios';
import LicenseTypeSelect from '../../Forms/components/LicenseTypeSelect';
import { setProcedure } from '../../../redux/actions/procedures';
import ParishSelect from '../../Forms/components/ParishSelect';
const server = process.env.REACT_APP_SERVER_URL;

const InternalBranchRegister: React.FC<InternalBranchRegisterProps> = ({ thm, inst, auth, prsh, setProcedure }) => {
  const [loading, setLoading] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const { width } = useWindowDimensions();
  const [form] = useForm();

  const submit = async () => {
    const values = await form.validateFields();
    const parroquia = prsh?.parish?.find( p => p.nombre === values.parroquia)?.id

    const body = {
      tipoTramite: values.estadoLicencia === 'PERMANENTE' ? 28 : 36,
      datos: {
        ...values,
        parroquia 
      }, 
    };
    console.log(body)
    setLoading(true);
    try {
      const response = await axios.post(`${server}/activities/submmitLicense`, body, { headers: { Authorization: `Bearer ${auth?.token}` } });
      if(response.status === 200) {
        message.success('Registro de sucursal procesado exitosamente.');
        form.resetFields();
      }
    } catch(e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al momento de registrar una sucursal');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ height: '100%' }} title='Registro de Sucursal' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white'}}>
      <Form form={form} layout='vertical' scrollToFirstError onFinish={submit} onValuesChange={(c, v) => { form.setFieldsValue(v)}}>
        <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Datos del Contribuyente</Typography.Title>
        </Divider>
        <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Col xs={24} xl={4}>
           <Form.Item label='Tipo de Documento' name='tipoDocumento' rules={[{ required: true, message: 'Debe seleccionar el tipo de documento' }]}>
            <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
              <Select.Option value='V'>V</Select.Option>
              <Select.Option value='E'>E</Select.Option>
              <Select.Option value='J'>J</Select.Option>
              <Select.Option value='G'>G</Select.Option>
              <Select.Option value='P'>P</Select.Option>
            </Select>
          </Form.Item>
          </Col>
            <Col xs={24} xl={8}>
                <Form.Item label='Documento de Identidad' name='documento' rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}>
                    <Input placeholder='Documento de Identidad'/>
                </Form.Item>
            </Col>
        </Row>
        <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Datos de la Sucursal</Typography.Title>
        </Divider>
        <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col xs={24} xl={8}>
                <Form.Item label='R.I.M.' name='referenciaMunicipal' rules={[{ required: true, message: 'Debe ingresar el rim' }]}>
                <Input placeholder='R.I.M.' />
                </Form.Item>
            </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Denominación Comercial' name='denominacionComercial' rules={[{ required: true, message: 'Debe ingresar la denominación comercial' }]}>
              <Input placeholder='Denominación Comercial' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Nombre del Representante Legal' name='nombreRepresentante' rules={[{ required: true, message: 'Debe ingresar el nombre del representante legal' }]}>
              <Input placeholder='Nombre del Representante Legal' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Capital Suscrito' name='capitalSuscrito' rules={[{ required: true, message: 'Debe ingresar el capital suscrito' }]} normalize={Utils.normalize.isNumber}>
              <Input placeholder='Capital Suscrito' type='numeric' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Tipo de Sociedad' name='tipoSociedadContrib' rules={[{ required: true, message: 'Debe ingresar el tipo de sociedad' }]}>
              <TaxpayerSocietyType placeholder='Tipo de Sociedad' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Teléfono' name='telefono' rules={[{ required: true, message: 'Debe ingresar el teléfono' }, { validator: Utils.validators.phoneNumber }]}>
              <Input placeholder='Teléfono' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Correo Electrónico' name='email' rules={[{ required: true, message: 'Debe ingresar un correo electrónico' }, { validator: Utils.validators.email }]}>
              <Input placeholder='Correo Electrónico' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Parroquia' name='parroquia' rules={[{ required: true, message: 'Debe ingresar la parroquia' }]}>
              <ParishSelect />
            </Form.Item>
          </Col>
          <Col xs={24} xl={24}>
            <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Debe ingresar la dirección' }]}>
              <Input placeholder='Dirección' />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Datos de la Licencia</Typography.Title>
        </Divider>
        <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Col xl={6} xs={24}>
            <Form.Item name='estadoLicencia' rules={[{ required: true, message: 'Debe ingresar el estado de la licencia' }]}>
              <LicenseTypeSelect placeholder='Tipo de Licencia' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={24} style={{ paddingLeft: 10, marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
        <Col xs={24} xl={8}>
          <Button type='primary' loading={loading} onClick={form.submit}>Generar</Button>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, prsh: state.prsh ,inst: state.inst.institutions, auth: state.auth });

export default connect(mapStateToProps, { setProcedure })(InternalBranchRegister);

interface InternalBranchRegisterProps {
  thm: States.ThemeColors
  inst: States.Institutions['institutions']
  auth: States.Auth
  prsh: States.Parish
  setProcedure:(tramite) => void
}