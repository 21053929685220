import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const editContributorRif = async (data, token) => {
  try {
    const response = await axios.patch(`${server}/contributor/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const editContributorRim = async (data, token) => {
  try {
    const response = await axios.patch(`${server}/contributor/rim/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getContributor = async (data, token) => {
  try {
    const response = await axios.get(`${server}/settlements/search/taxPayer?doc=${data.documento}&pref=${data.tipoDocumento}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
}

