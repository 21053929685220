import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Modal, Form, Divider, Typography, Row, Col, Input, Select, Button, Collapse, Descriptions, Empty, Spin, InputNumber, Tooltip, Popconfirm } from 'antd';
import { Categoria, State, States, VehicleType } from 'sigt';
import { useWindowDimensions } from '../../../utils/hooks';
import { CreditCardOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Utils } from '../../../utils/validators';
import { getVehicleTypes, deleteVehicle, registerVehicle, registerVehicleInternal } from '../../../redux/actions/vehicles';
import handlingMessage from '../../../utils/handlingMessage';
import { useHistory } from 'react-router';
import moment from 'moment';

const RegisterVehicles : React.FC<RegisterVehiclesProps> = ({ auth, thm, vhc, registerVehicle, deleteVehicle, registerVehicleInternal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [tipo, setTipo] = useState<number>(-1);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [subCategorias, setSubCategorias] = useState<{ id: number, descripcion: string, costo: string }[]>([]);
  const { width } = useWindowDimensions();
  const { brands, types, vehicles, id } = vhc;
  const history = useHistory();

  const carBodiesTypes = [ 'AMBULANCIA', 'ARRASTRE', 'ARTICULADO', 'ATV', 'AUTOBUS', 'AUTOBUSETE', 'BARANDA/HIERRO', 'BATEA', 
                        'BLINDADO', 'BOMBEADOR DE CONCRETO', 'CABINA', 'CAMION TRACTOR', 'CARGADOR', 'CASA RODANTE', 'CASILLERO',
                        'CAVA', 'CAVA/GRANEL', 'CESTA', 'CESTA CANERA', 'CHASIS', 'CHASIS AUTOBUS', 'CHASIS CABINA', 'CHASIS LARGO', 'CHASIS MINIBUS', 
                        'CHOPPER', 'CHUTO', 'CISTERNA', 'COLECTIVO', 'COMPACTADORA', 'COMPACTO MPV', 'CONVERTIBLE', 'COSECHADORA', 'COUPE', 
                        'COUPE CONVERTIBLE', 'CROSS', 'CROSSOVER', 'CUSTON', 'ENDURO', 'ESPECIAL', 'ESTACA/BARANDA', 'ESTACA/ESTRUC/HIERRO', 'ESTACAS',
                        'EXCAVADORA', 'FERRETERO', 'FUNERARIO', 'FURGON', 'FURGON/CACHUCHA', 'GABINETE', 'GATO HIDRAULICO', 'GEOFISICO', 'GRANEL', 'GRUA',
                        'GRUA DE PLATAFORMA','HATCH BACK','JAULA','JAULA GANADERA','JAULA POLLERA','LIMOUSINE','LOW-BOY','MEZCLADORA','MINIBUS','MINIVAN',
                        'MONTA CARGA','MOTOCICLETA','MOTONETA','NODRIZA','PALA MECANICA','PANEL','PASEO','PICK-UP','PICK-UP C/CABINA','PICK-UP D/CABINA',
                        'PICK-UP/BARANDA','PICK-UP/BARANDA/HIERRO', 'PICK-UP/C CABINA', 'PICK-UP/C FURGON', 'PLATABANDA', 'PLATAFORMA', 'PLATAFORMA DESPLAZABLE',
                        'PLATAFORMA FIJA', 'PLATF/B HIDRAULICO', 'PLATF/BARANDA', 'PLATF/CACHUCHA', 'PLATF/ESTACA', 'PLATF/ESTRUC/HIERRO', 'PLATF/G HIDRAULICO', 
                        'PORTA CONTENEDORES', 'QUADS', 'RACING', 'RANCHERA', 'RANCING', 'RETRO EXCAVADORA', 'RUSTICO', 'SCOOTER', 'SEDAN', 'SEDAN CONVERTIBLE', 
                        'SILO', 'SPORT WAGON', 'SPORT WAGON CONVERTIBLE', 'STATION WAGON', 'SUPERVAN', 'TANQUE', 'TECHO DE LONA', 'TECHO DURO', 'TRACCION DE SANGRE',
                        'TRACTOR', 'TRANSPORTADOR', 'TRAYLER', 'TRIMOVIL', 'UTILITARIO', 'VACUUM', 'VAN', 'VOLTEO' ];

  const onCarRegister = async () => {
    const values = await form.validateFields();
    setLoading(true);
    handlingMessage({
      action: () => {
        return auth.user?.tipoUsuario === 4 ? registerVehicle(values) : registerVehicleInternal(values, id);
      },
      loadingMessage: 'Registrando vehículo...',
      key: 'carRegister',
      cb: (data) => {
        if(data.status === 201){
          setModal(false);
          form.resetFields();
        }
        setLoading(false);
      }
    });
  }

  const onCarDelete = async (id: number) => {
    setDeleting(true);
    handlingMessage({
      action: () => deleteVehicle(id),
      loadingMessage: 'Eliminando vehículo...',
      key: 'carDeletion',
      cb: () => {
        setDeleting(false);
      }
    });
  }

  const payButton = (lastUpdateDate, id: number, subcategoria: number) => {
    const today = moment();
    const type = vhc.subCategories.find((sc) => sc.id === subcategoria)?.tipo;
    const PayButton = <Button style={{ marginTop: 20 }} type='primary' icon={<CreditCardOutlined/>} onClick={() => history.push(`./vehiculos/pagar/${id}`)}>Pagar Impuesto</Button>;
    if (!lastUpdateDate) return PayButton;
    else {
      if ((type === 1 && (moment(lastUpdateDate).diff(today, 'months')) < 0) || (type === 2 && (moment(lastUpdateDate).diff(today, 'years')) < 0))
        return PayButton;
      else
        return null;
    }
  }

  return <Card style={{ height: '100%' }}  title='Impuesto de Vehículos' bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
  headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white'}} >
    <Button type='primary' icon={<PlusOutlined/>} onClick={() => setModal(true)}>Registrar Vehículo</Button>
    {(vhc.types.length <= 0) ? <Spin tip='Cargando Vehiculos...' className='custom-spinner' style={{ height: 400 }} indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '12px 0px' }} spin/>} size='large'/> :
      <>{ vehicles.length <= 0 ? <Empty style={{ padding: '100px 0 0 0', margin: '20px 0 0 0', height: 400, border: '1px solid lightgrey', borderRadius: 5 }} description='No posee vehículos registrados'/> :
        <Collapse style={{ marginTop: 20 }}>
          {vehicles.map((v) => 
            <Collapse.Panel header={`PLACA: ${v.placa?.toUpperCase()} - MODELO: ${v.modelo?.toUpperCase()}`} key={v.id} extra={<Popconfirm title='Esta seguro desea eliminar este vehiculo?' onCancel={(e) =>  e?.stopPropagation()} onConfirm={(e) => { onCarDelete(v.id); e?.stopPropagation(); }} okText="Si" cancelText="No">
              <Button type='danger' size='small' loading={deleting} icon={<DeleteOutlined/>} onClick={(e) => { e.stopPropagation() }} />
            </Popconfirm>}>
              <Descriptions column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
                <Descriptions.Item label='Placa'>{v.placa}</Descriptions.Item>
                <Descriptions.Item label='Clasificación'>{vhc.subCategories.find((sc) => sc.id === v.subcategoria)?.descripcion || ''}</Descriptions.Item>
                <Descriptions.Item label='Marca'>{v.marca}</Descriptions.Item>
                <Descriptions.Item label='Modelo'>{v.modelo}</Descriptions.Item>
                <Descriptions.Item label='Tipo Carroceria'>{v.tipoCarroceria}</Descriptions.Item>
                <Descriptions.Item label='Color'>{v.color}</Descriptions.Item>
                <Descriptions.Item label='Año'>{v.anio}</Descriptions.Item>
                <Descriptions.Item label='Serial de Carroceria'>{v.serialCarroceria}</Descriptions.Item>
                <Descriptions.Item label='Tipo de Combustible'>{v.tipoCombustible}</Descriptions.Item>
              </Descriptions>
              {payButton(v.fechaUltimaActualizacion ? moment(v.fechaUltimaActualizacion).startOf('month') : v.fechaUltimaActualizacion, v.id, v.subcategoria)}
            </Collapse.Panel> 
          )}
        </Collapse>}
    </>}
    <Modal title='Registro de Vehículo' bodyStyle={{padding: '30px 50px', height: '70vh', overflowY: 'scroll'}} centered confirmLoading={loading} width={900} maskClosable visible={modal} okText='Finalizar' onCancel={() => {setModal(false); form.resetFields(); setTipo(-1)}} onOk={onCarRegister}>
      <Form layout='vertical' form={form}>
      <Divider orientation='left' style={{ marginLeft: -30 }}><Typography.Title level={4}>Datos del vehículo</Typography.Title></Divider>
        <Row gutter={16}> 
          <Col xl={8} xs={24}>
            <Form.Item label='Placa' name='placa' normalize={Utils.normalize.toUpper} rules={[{ required: true, message: 'Debe ingresar el Placa' }]}>
              <Input placeholder='Placa' minLength={6} maxLength={7} />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item label='Marca' name='marca' rules={[{ required: true, message: 'Debe seleccionar marca del vehículo.' }]}>
              <Select showSearch filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())} placeholder='Marca'>
                {brands?.map(b => <Select.Option value={b.id} key={b.id} title={b.nombre?.toUpperCase()}>{b.nombre}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item label='Modelo' name='modelo' rules={[{ required: true, message: 'Debe seleccionar modelo del vehículo.' }]}>
              <Input placeholder='Modelo'/>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item label='Año' name='anio' rules={[{ required: true, message: 'Debe ingresar año del vehículo.' }]}>
              <InputNumber style={{ width: '100%' }} max={9999} min={1800} placeholder='Año'/>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item label='Color' name='color' rules={[{ required: true, message: 'Debe ingresar color del vehículo.' }]}>
              <Input placeholder='Color'/>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item label='Tipo de Carrocería' name='tipoCarroceria' rules={[{ required: true, message: 'Debe ingresar el tipo del combustible' }]}>
              <Select showSearch filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())} placeholder='Tipo de Carrocería'>
                {carBodiesTypes.map((ct) => <Select.Option key={ct} value={ct} title={ct}>{ct}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item label='Tipo de Vehículo' name='tipo' rules={[{ required: true, message: 'Debe seleccionar el tipo de vehículo' }]}>
              <Select placeholder='Tipo de Vehículo' onChange={(tipo:number) => { setTipo(tipo); setCategorias(types[tipo]?.categorias); form.setFieldsValue({ categoria: [], subcategoria: [] })}}>
                {types.map((t: VehicleType, i: number) => <Select.Option value={i} key={t.id}>{t.descripcion}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} xs={24} >
            <Form.Item label='Categoria' name='categoria' rules={[{ required: true, message: 'Debe seleccionar el categoria de vehículo' }]}>
              <Select disabled={tipo === -1} placeholder='Categoria' onChange={(categoria:number) => { setSubCategorias(types[tipo]?.categorias[categoria]?.subcategorias); form.setFieldsValue({ subcategoria: [] }) }} >
                {types[tipo]?.categorias.map((c,i) => <Select.Option value={i} key={c.id}>{c.descripcion}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item label='Sub-Categoria' name='subcategoria' rules={[{ required: true, message: 'Debe seleccionar el sub-categoria de vehículo' }]}>
              <Select disabled={subCategorias.length <= 0} placeholder='Sub-Categoria'>
                {subCategorias.map((sc) => <Select.Option value={sc.id} key={sc.id}><Tooltip title={sc.descripcion}>{sc.descripcion}</Tooltip></Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item label='Tipo de Combustible' name='tipoCombustible' rules={[{ required: true, message: 'Debe ingresar el tipo del combustible' }]}>
              <Select placeholder='Tipo de Combustible'>
                <Select.Option value='GASOLINA' title='GASOLINA'>GASOLINA</Select.Option>
                <Select.Option value='GASOIL' title='GASOIL'>GASOIL</Select.Option>
                <Select.Option value='GAS' title='GAS'>GAS</Select.Option>
                <Select.Option value='ELECTRICIDAD' title='ELECTRICIDAD'>ELECTRICIDAD</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item label='Serial Carrocería' name='serialCarroceria' rules={[{ required: true, message: 'Debe ingresar el serial de la carroceria' }]}>
              <Input placeholder='Serial Carrocería' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  </Card>;
}

const mapStateToProps = ({ auth, thm, vhc } : State) => ({ auth, thm, vhc });

export default connect(mapStateToProps, { getVehicleTypes, registerVehicle, deleteVehicle, registerVehicleInternal })(RegisterVehicles);

interface RegisterVehiclesProps {
  thm: States.ThemeColors
  vhc: States.Vehicles
  auth: States.Auth
  registerVehicle: (vehicle: any) => Promise<any>
  deleteVehicle: (id: number) => Promise<any>
  registerVehicleInternal: (vehicle: any, id: number | undefined) => Promise<any>;
}