import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Select, Divider, Button, Typography, InputNumber } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useWindowDimensions } from '../../../utils/hooks';

const MultiplePropertyValues: React.FC<MultiplePropertyValuesProps> = ({ yearsArray, form, loading }) => {
  const [years, setYears] = useState<number[]>(yearsArray);
  const { width } = useWindowDimensions();

  const firstListItem = (fields, add) => {
    if(fields.length === 0) {
      setTimeout(() => add(), 10);
    }
  };
  const change = () => {
    const aux:number[] = form.getFieldsValue().avaluos.filter(e => e !== undefined).map(e => e.anio).filter(e => e !== undefined);
    setYears(yearsArray.filter(e => !aux.includes(e)));
  }

  useEffect(() => {
    setYears(yearsArray)
    // eslint-disable-next-line
  }, [yearsArray]);

  const handleChange = () => {
    change();
  }
  const handlingAdd = (add) => {
    add();
    change();
  }
  const handlingRemove = (fields, remove) => {
    remove(fields.length - 1);
    change();
   } 

  return (
    <React.Fragment>
      <Form.List name='avaluos' >
        {(fields, { add, remove }) => (
          <div>
            {!loading && firstListItem(fields, add)}
            {fields.map((field, index) => (
              <React.Fragment key={field.key}>
                <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Avalúo {index + 1}
                  </Typography.Text>
                </Divider>
                  <Row gutter={24} style={{ padding: '0 20px' }}>
                    <Col xl={6} xs={24}>
                      <Form.Item label='Año' name={[field.name, 'anio']} key={index} rules={[{ required: true, message: 'Debe indicar el año' }]}>
                        <Select placeholder='Año' onChange={()=> handleChange()}>{years.map((ele, index)=><Select.Option key={index} value={ele}>{ele}</Select.Option>)}</Select>
                      </Form.Item>
                    </Col>
                    <Col xl={6} xs={24}>
                      <Form.Item label='Monto' name={[field.name, 'avaluo']} key={index} rules={[{ required: true, message: 'Por favor ingrese el monto' }]}>
                        <InputNumber placeholder='Monto' formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                          parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''} min={0} step={1} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>                    
                  </Row>
              </React.Fragment>
            ))}
            <Row style={{width: width > 992 ? '50%' : '100%'}} gutter={[16, 16]} justify='center' align='middle'>
              {fields.length < 5 &&
                <Col>
                  <Button onClick={() => handlingAdd(add)}>Agregar Avaluo</Button>
                </Col>}
                {fields.length > 1 &&
                <Col>
                  <Button type='danger' onClick={() => handlingRemove(fields, remove)}>Eliminar Avaluo</Button>
                </Col>}
            </Row>
          </div>
        )}
      </Form.List>
    </React.Fragment>
  );
};


export default MultiplePropertyValues;

interface MultiplePropertyValuesProps {
  yearsArray: number[]
  loading: boolean
  form: FormInstance
}