import React, { useEffect, useRef, useState, createRef } from 'react';
import { States, State, Campos, Bill } from 'sigt';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch, useParams } from 'react-router';
import { useWindowDimensions } from '../../utils/hooks';
import { Card, Form, Divider, Row, Typography, Col, Select, Button, message, Modal } from 'antd';
import { getValidators, ExtendedDecoratorOptions } from '../../utils/formGenValidators';
import { SelectProps } from 'antd/lib/select';
import { CloudDownloadOutlined, RocketOutlined } from '@ant-design/icons';
import moment from 'moment';
import OrdinanceBill from '../OrdinanceBill';
import { flatten } from 'lodash';
import { updateProcedure } from '../../redux/actions/procedures';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { processNameSelector } from './components/ProcessSelect';
import { formatNumber } from '../../utils/formatNumber';
import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

const FormGenerator: React.FC<FormGeneratorProps> = ({ auth, prcd, inst, updateProcedure, thm }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rejecting, setRejecting] = useState<boolean>(false);
  const [bill, setBill] = useState<Bill>();
  const history = useHistory();
  useRouteMatch('/dashboard/procesarTramite/:idTramite');
  const { idTramite } = useParams<{ idTramite: any }>();
  const { width } = useWindowDimensions();
  const selectedPrcd = prcd.procedures.find((p) => p.id === parseInt(idTramite || '0'));
  const selectedPrcdClass = inst.institutions
    ?.find((i) => i.id === auth.user?.institucion.id)
    ?.tramitesDisponibles?.find((t) => t.id === selectedPrcd?.tipoTramite);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const refs = useRef<any>([]);
  const [values, setValues] = useState<any>({});
  const [report, setReport] = useState<string | null>(null);
  const [data, setData] = useState<any>({});

  const liqueurPrcd = [29, 30, 31, 32, 33, 34, 35];
  const rejectable = [23, 24, 25, 28, 27, 36, ...liqueurPrcd];
  const canBeRejected = !!rejectable.find((id) => selectedPrcdClass?.id === id);

  useEffect(() => {
    if (auth.user && auth.user.tipoUsuario === 4) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    refs.current = selectedPrcdClass ? selectedPrcdClass?.secciones?.map((s) => s.campos.map(() => createRef())) : [];
    if (auth.user?.institucion?.cargo?.id === 46 && selectedPrcdClass) {
      selectedPrcdClass.secciones = selectedPrcdClass?.secciones?.filter((ele) => ele.id !== 32);
    }
    // if (auth.user?.institucion?.cargo?.id === 93 && selectedPrcdClass) {
    //   selectedPrcdClass.secciones = selectedPrcdClass?.secciones?.filter((ele) => ele.id !== 15);
    // }
    let datosUsuario = Object.assign({}, selectedPrcd?.datos?.usuario ? selectedPrcd?.datos?.usuario : selectedPrcd?.datos);
    Object.keys(datosUsuario).forEach((k) => {
      if (k.includes('fecha')) datosUsuario[k] = moment(datosUsuario[k]).locale('es');
    });
    const datosFuncionario = Object.assign({}, selectedPrcd?.datos.funcionario);
    const croquis = datosFuncionario.croquis;
    const planoConstruccion = datosFuncionario.planoConstruccion;
    datosFuncionario.croquis = undefined;
    datosFuncionario.planoConstruccion = undefined;
    fetchBlobs({ croquis, planoConstruccion });
    datosFuncionario.metrosCuadradosConstruccion = datosUsuario?.codCat?.metrosConstruccion;
    datosFuncionario.areaConstruccion = datosUsuario?.codCat?.metrosConstruccion;
    if (datosFuncionario) datosUsuario = { ...datosUsuario, ...datosFuncionario };
    setValues(datosUsuario);
    form.setFieldsValue(datosUsuario);
    return () => {
      refs.current = [];
    };
    // eslint-disable-next-line
  }, [idTramite, prcd.procedures, selectedPrcdClass, selectedPrcd]);

  useEffect(() => {
    if (prcd.procedures.length > 0 && !selectedPrcd) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [prcd]);

  const getFileName = (k: string) => {
    switch (k) {
      case 'croquis':
        return 'CROQUIS_UBICACION.png';
      case 'planoConstruccion':
        return 'PLANO_CONSTRUCCION.png';
      default:
        return 'IMAGE.png';
    }
  };

  const fetchBlobs = ({ ...urls }) => {
    Object.keys(urls)
      .filter((u) => urls[u])
      .forEach((url) => {
        fetch(urls[url], { mode: 'no-cors' })
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], getFileName(url));
            form.setFieldsValue({
              [url]: [
                {
                  uid: `${url.toUpperCase()}${Date.now()}`,
                  lastModified: Date.now(),
                  lastModifiedDate: new Date(),
                  name: getFileName(url),
                  size: blob.size,
                  type: 'image/png',
                  percent: 0,
                  status: 'done',
                  thumbUrl: urls[url],
                  response: undefined,
                  xhr: file,
                },
              ],
            });
          });
      });
  };

  const getFormItem = (fields: Campos[], sectIndex: number): JSX.Element[] => {
    const filteredFields = fields.filter((field, index) => fields.findIndex(f => f.id === field.id) === index);
    return filteredFields.map((f, i) => {
      const struct = getValidators(
        f.validacion,
        auth.user?.tipoUsuario || 4,
        selectedPrcdClass?.id,
        undefined,
        selectedPrcd?.tipoTramite,
        auth.user?.institucion?.cargo?.id
      );
      const { extension } = struct;
      return !extension.rte ? (
        <Col xs={24} xl={f.col} key={f.id}>
          <Form.Item
            label={extension.rte || extension.hidden ? undefined : f.nombre}
            name={f.validacion}
            rules={struct.validations.rules}
            normalize={struct.validations.normalize}
            valuePropName={extension.valuePropName}
            getValueFromEvent={extension.getValueFromEvent}
          >
            {getChildComponent(extension, sectIndex, i)}
          </Form.Item>
        </Col>
      ) : (
        <Col xs={24} xl={f.col} key={f.id}>
          <ReactQuill {...extension.props} value={report} onChange={(e) => setReport(e)} />
        </Col>
      );
    });
  };

  const getChildComponent = (extension: ExtendedDecoratorOptions, sectIndex: number, fieldIndex: number): JSX.Element => {
    const { Component } = extension;
    const Icon = extension.icon;
    return (
      <Component
        {...extension.props}
        prefix={Icon ? <Icon /> : null}
        addonBefore={
          extension.addonBefore
            ? getAddonBefore(
                extension.addonBefore.Component,
                extension.addonBefore.options || [],
                sectIndex,
                fieldIndex,
                extension.addonBefore.name,
                extension.addonBefore?.props
              )
            : null
        }
        form={form}
        data={data}
        setData={setData}
        preloaded={values.actividadesEconomicas}
      />
    );
  };

  const getAddonBefore = (
    Component: typeof Select,
    options: string[],
    sectIndex: number,
    fieldIndex: number,
    name: string,
    props?: SelectProps<any>
  ): JSX.Element => {
    if (!values[name]) setValues({ ...values, [name]: options[0] });
    const setValue = (v) => {
      setValues({ ...values, [name]: v });
    };
    return (
      <Component
        {...props}
        defaultValue={options[0]}
        ref={(ref) => {
          if (ref && refs.current && refs.current.length > 0) refs.current[sectIndex][fieldIndex] = { name, ref };
        }}
        value={values ? values[name] : options[0]}
        onChange={(c) => setValue(c)}
      >
        {options.map((opt, i) => (
          <Component.Option value={opt} key={`opt-${i}`}>
            {opt}
          </Component.Option>
        ))}
      </Component>
    );
  };

  const handleSubmit = async () => {
    if (
      (bill && bill.totalBs > 0) ||
      selectedPrcdClass?.pagoPrevio ||
      selectedPrcdClass?.id === 27 ||
      selectedPrcdClass?.id === 5 ||
      selectedPrcdClass?.id === 26
    ) {
      const values = await form.validateFields();
      if (values?.estimacionSimple?.terreno?.area)
        values.estimacionSimple.terreno.area =
          values.estimacionSimple.esTerreno && formatNumber(values.estimacionSimple.terreno.area);
      if (values?.estimacionSimple?.construccion?.area)
        values.estimacionSimple.construccion.area =
          values.estimacionSimple.esConstruccion && formatNumber(values.estimacionSimple.construccion.area);
      if (auth.user?.institucion?.cargo?.id === 93 && ((selectedPrcd?.tipoTramite) === 43 || (selectedPrcd?.tipoTramite) === 16)) {
        const areaTerreno = values.estimacionSimple.esTerreno ? values.estimacionSimple.terreno.area : '';
        const areaConstruccion = values.estimacionSimple.esConstruccion ? values.estimacionSimple.construccion.area : '';
        const ubicadoEn = values.ubicadoEn || '';
        const parroquiaEdificio = values.parroquiaEdificio || '';
        await axios.get(
          `${server}/reportSolvRRI?id=${idTramite}&areaTerreno=${areaTerreno}&areaConstruccion=${areaConstruccion}&codigoRRI=${values.inputRRI}&ubicadoEn=${ubicadoEn}&parroquiaEdificio=${parroquiaEdificio}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
      }
      flatten<any>(refs.current)
        .filter((r) => r.current !== null)
        .forEach((i) => {
          if (i.ref) values[i.name] = i.ref.props.value;
        });
      setLoading(true);
      Object.keys(values).forEach((k) => {
        if (moment.isMoment(values[k])) {
          values[k] = values[k].toISOString();
        }
        if (Array.isArray(values[k]) && values[k][0].hasOwnProperty('uid')) {
          values[k] = values[k][0].response ? values[k][0].response.archivos[0] : values[k][0].thumbUrl;
        }
      });
      if (values.actividadesEconomicas) {
        values.actividadesEconomicas.forEach((ae) => {
          if (moment.isMoment(ae.desde)) ae.desde = ae.desde.toISOString();
        });
      }
      if (values.metrosCuadradosConstruccion) values.metrosCuadradosConstruccion = +values.metrosCuadradosConstruccion;
      const datos: any = { ...values, informe: report || undefined };
      if (auth.user?.institucion?.cargo?.id === 46) {
        datos.inputRRI = selectedPrcd?.datos?.funcionario?.inputRRI;
      }
      datos.costo = bill?.totalBs || selectedPrcdClass?.costo;
      const tramite = {
        estado:
          auth.user?.tipoUsuario === 2
            ? 'enrevision'
            : selectedPrcdClass?.id === 43 && auth.user?.institucion?.cargo?.id === 93
            ? 'enprocesocat'
            : 'enproceso',
        tipoTramite: selectedPrcdClass?.id,
        datos,
        idTramite: parseInt(idTramite || '0'),
        bill,
        ...(auth.user?.tipoUsuario === 2
          ? {
              revision: datos.tipoProceso
                ? { aprobado: processNameSelector(datos.tipoProceso, selectedPrcdClass?.id, auth.user?.institucion?.cargo?.id) }
                : { aprobado: !rejecting },
            }
          : {
              aprobado: datos.tipoProceso
                ? processNameSelector(datos.tipoProceso, selectedPrcdClass?.id, auth.user?.institucion?.cargo?.id)
                : !rejecting,
            }),
      };
      if (datos.estimacionSimple && !datos.estimacionSimple.esTerreno && !datos.estimacionSimple.esConstruccion) {
        message.error('Debe indicar si el tipo de inmueble es terreno, construcción, o ambos para calcular el valor fiscal');
      }
      try {
        await updateProcedure({ tramite, idUsuario: auth.user?.id }, auth.token || '');
        message.success('Tramite procesado de manera exitosa');
        history.push('/dashboard/bandejas');
        setLoading(false);
      } catch (e) {
        message.error('Error al procesar la solicitud');
        setLoading(false);
      }
    } else {
      message.error('Debe agregar items al presupuesto que debera ser pagado por el usuario.');
    }
  };

  const rejectProcedure = async () => {
    if (bill || selectedPrcdClass?.pagoPrevio) {
      let _datos: any = undefined;
      if (auth.user?.tipoUsuario === 2) {
        const values = await form.validateFields();
        flatten<any>(refs.current)
          .filter((r) => r.current !== null)
          .forEach((i) => {
            if (i.ref) values[i.name] = i.ref.props.value;
          });
        setLoading(true);
        Object.keys(values).forEach((k) => {
          if (moment.isMoment(values[k])) {
            values[k] = values[k].toISOString();
          }
          if (Array.isArray(values[k]) && values[k][0].hasOwnProperty('uid')) {
            values[k] = values[k][0].response ? values[k][0].response.archivos[0] : values[k][0].thumbUrl;
          }
        });
        if (values.actividadesEconomicas) {
          values.actividadesEconomicas.forEach((ae) => {
            if (moment.isMoment(ae.desde)) ae.desde = ae.desde.toISOString();
          });
        }
        _datos = { ...values, informe: report || undefined };
        _datos.costo = bill?.totalBs || selectedPrcdClass?.costo;
      }
      setLoading(true);
      const datos: any = auth.user?.tipoUsuario === 2 ? _datos : { ...selectedPrcd?.datos, informe: report || undefined };
      const tramite = {
        estado:
          auth.user?.tipoUsuario === 2
            ? 'enrevision'
            : selectedPrcdClass?.id === 43 && auth.user?.institucion?.cargo?.id === 93
            ? 'enprocesocat'
            : 'enproceso',
        datos,
        bill,
        idTramite: parseInt(idTramite || '0'),
        ...(auth.user?.tipoUsuario === 2 ? { revision: { aprobado: false } } : { aprobado: false }),
      };
      try {
        await updateProcedure({ tramite, idUsuario: auth.user?.id }, auth.token || '');
        setLoading(false);
        message.success('Tramite rechazado de manera exitosa');
        history.push('/dashboard/bandejas');
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al procesar la solicitud');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      message.error('Debe agregar items al presupuesto que debera ser pagado por el usuario.');
    }
  };

  const handleAprobarGerente = async () => {
    history.push(`/dashboard/bandejas`, {toVerificate : true, estado:'enrevision_gerente', tramite: selectedPrcd})
  }

  return selectedPrcd ? (
    <Card
      style={{ height: '100%' }}
      title={selectedPrcd?.nombreTramiteLargo}
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text strong>Codigo de Tramite: {selectedPrcd.codigoTramite}</Typography.Text>
        <Typography.Text strong>Fecha: {moment(selectedPrcd.fechaCreacion).format('DD-MM-YYYY')}</Typography.Text>
      </div>

      {selectedPrcd.estado === 'encorreccion' ? (
        <Card
          title={
            <Typography.Text strong>
              <b style={{ color: 'red', marginRight: 2 }}>*</b>
              Observaciones
            </Typography.Text>
          }
          style={{ width: 300, marginTop: 20 }}
        >
          {form.getFieldValue('observacionProceso')}
        </Card>
      ) : null}

      <Modal visible={rejecting} onCancel={() => setRejecting(false)} onOk={() => rejectProcedure()} title='Informe de Rechazo'>
        <Col span={24}>
          <ReactQuill value={report || ''} onChange={(e) => setReport(e)} style={{ height: '200px', marginBottom: '50px' }} />
        </Col>
      </Modal>
      {selectedPrcd?.planilla && (
        <React.Fragment>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Planilla de Solicitud
            </Typography.Title>
          </Divider>
          <Button
            type='primary'
            target='_blank'
            href={selectedPrcd.planilla}
            icon={<CloudDownloadOutlined />}
            style={{ marginBottom: 10 }}
          >
            Ver Planilla de Solicitud
          </Button>
        </React.Fragment>
      )}
      {selectedPrcd.recaudos?.length > 0 && (
        <React.Fragment>
          <Divider orientation='left' style={{ marginLeft: -20 }}>
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              Recaudos
            </Typography.Title>
          </Divider>
          <Button type='primary' icon={<CloudDownloadOutlined />} onClick={() => setVisible(true)} style={{ marginBottom: 10 }}>
            Ver Recaudos
          </Button>
          <Modal
            width={width < 992 ? '80%' : '60%'}
            visible={visible}
            title='Recaudos'
            onCancel={() => setVisible(false)}
            centered
            footer={[]}
          >
            <Row gutter={24}>
              {selectedPrcdClass?.recaudos
                .filter((r) => !!selectedPrcd?.recaudos.find((rc) => rc.includes(r.nombreCorto)))
                .map((r) => (
                  <Col key={r.id} xl={6} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography.Text strong style={{ textAlign: 'center', marginBottom: 10 }}>
                      {r.nombreCompleto}
                    </Typography.Text>
                    <Button type='primary' target='_blank' href={selectedPrcd?.recaudos.find((rc) => rc.includes(r.nombreCorto))}>
                      Descargar Recaudo
                    </Button>
                  </Col>
                ))}
            </Row>
          </Modal>
        </React.Fragment>
      )}
      <Form
        scrollToFirstError
        form={form}
        layout='vertical'
        style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
        onFinish={handleSubmit}
        onValuesChange={(c, v) => {
          form.setFieldsValue(v);
          setData(v);
        }}
      >
        {selectedPrcdClass?.secciones?.map((s, i) => (
          <React.Fragment key={s.id}>
            <Divider orientation='left' style={{ marginLeft: -35 }}>
              <Typography.Title level={4}>{s.nombre}</Typography.Title>
            </Divider>
            <Row gutter={24}>
              {getFormItem(
                s.campos
                  .filter((c) => c.estado === 'enproceso')
                  .sort((a, b) => {
                    if (a.orden > b.orden) return 1;
                    if (b.orden > a.orden) return -1;
                    return 0;
                  }) || [],
                i
              )}
            </Row>
          </React.Fragment>
        ))}
      </Form>
      {!selectedPrcdClass?.pagoPrevio &&
        selectedPrcdClass?.id !== 27 &&
        selectedPrcdClass?.id !== 5 &&
        selectedPrcdClass?.id !== 26 && (
          <React.Fragment>
            <Divider orientation='left' style={{ marginLeft: -20 }}>
              <Typography.Title level={4}>Presupuesto</Typography.Title>
            </Divider>
            <OrdinanceBill
              codCatId={selectedPrcd.datos?.usuario?.codCat?.codCatastral}
              procedureType={selectedPrcd.tipoTramite}
              handleSetBill={setBill}
            />
          </React.Fragment>
        )}
      <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Button onClick={() => history.goBack()}>Atrás</Button>
        </Col>
        { auth?.user?.institucion?.cargo?.id !== 18 ?
        <Row gutter={12}>
          <Col>
            <Button loading={loading} type='primary' onClick={form.submit}>
              {selectedPrcdClass?.id === 27 || selectedPrcdClass?.id === 28 ? 'Aprobar' : 'Generar Certificado'}
            </Button>
          </Col>
          <Col>
            {canBeRejected && (
              <Button
                loading={loading}
                type='danger'
                onClick={() => (auth.user?.tipoUsuario === 2 ? rejectProcedure() : setRejecting(true))}
              >
                Rechazar
              </Button>
            )}
          </Col>
        </Row>
        :
        <Row gutter={12}>
          <Col>
          <Button
                shape='round'
                type='danger'
                icon={<RocketOutlined />}
                onClick={() => handleAprobarGerente()}
              >
                {width > 440 ? 'Aprobar' : null}
              </Button>
          </Col>
        </Row>
        }
      </Row>
    </Card>
  ) : null;
};

interface FormGeneratorProps {
  auth: States.Auth;
  prcd: States.Procedures;
  inst: States.Institutions;
  thm: States.ThemeColors;
  updateProcedure: (data: any, token: string) => Promise<void>;
}

const mapStateToProps = (state: State) => ({ auth: state.auth, prcd: state.prcd, inst: state.inst, thm: state.thm });

export default connect(mapStateToProps, { updateProcedure })(FormGenerator);
