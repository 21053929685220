import React from 'react';
import { Layout, Row, Col } from 'antd';
import Alcaldia from '../assets/images/logo-alcaldia-white.png';
import { ReactComponent as AlcaldiaLogo } from '../assets/images/svg/ALCALDIA-BOLIVARIANA-INDEPENDENCIA.svg';
import '../assets/css/layouts/ExternalLayout.css';
import { useWindowDimensions } from '../utils/hooks';
import { useLocation } from 'react-router-dom';
import img from '../assets/images/suite-logo-white.png';
import sistemaCertificado from '../assets/images/sistema-certificado.png';

const { Content, Header } = Layout;

const ExternalLayout: React.FC = ({ children }) => {
  const { width } = useWindowDimensions();
  const location = useLocation();

  return (
    <Layout id='main' style={{ height: '100vh' }}>
      <Header id='header' style={{ padding: `0 60px`, zIndex: 2 }}>
        <Row gutter={60} align='stretch' justify={width < 480 ? 'center' : 'end'} style={{ flexWrap: 'nowrap' }}>
          {width < 480 ? null : (
            <React.Fragment>
              <Col>
                <div style={{ height: '320px', width: '320px', position: 'fixed', top: '3%', right: '4%' }}>
                  <AlcaldiaLogo />
                </div>
                {/* <a href='https://maracaibo.gob.ve' target='_blank' rel='noopener noreferrer'>
                  aaaa
                </a> */}
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Header>
      <Content style={{ display: 'flex', zIndex: 2, alignItems: 'center', backgroundColor: 'transparent' }}>
        {location.pathname.includes('ingresar') ? (
          <Row id='login-content' align='middle' gutter={8}>
            <Col style={{ marginLeft: width > 400 ? 50 : 0 }}>{children}</Col>
          </Row>
        ) : (
          <Row id='main-content'>{children}</Row>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: 20,
            height: '100%',
            position: 'fixed',
            right: 0,
            paddingRight: 30,
            paddingBottom: 80,
          }}
        >
          <div
            onClick={() => {
              window.open('https://www.reportes.wakusoftware.com/sut.html', '_blank');
            }}
            className='burp-suite-logo'
          >
            <div
              style={{
                width: 68.2 * 3,
                height: 38.4 * 3,
                // position: 'fixed',
                // right: '4vw',
                // bottom: 0,
              }}
            >
              <img src={img} alt='asd' width={'100%'} height={'100%'} style={{ cursor: 'pointer' }} />
            </div>
          </div>

          <div>
            <img src={sistemaCertificado} alt='asd' width={200} height={100} />
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default ExternalLayout;
